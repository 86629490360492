import { Popover } from 'react-bootstrap';

export const datadogSitePopover = (
    <Popover id="datadogSitePopover" style={{ maxWidth: '800px' }}>
      <Popover.Header as="h3" className="d-flex justify-content-between align-items-center">
        Datadog Site Reference
      </Popover.Header>
      <Popover.Body>
        <p style={{fontSize: "0.9em"}}>For datadog site, refer to your datadog website url and enter the corresponding Site Parameter.</p>
        <div style={{fontSize: "0.9em"}} className="table-responsive">
          <table className="table table-bordered table-striped">
            <thead>
              <tr>
                <th>SITE</th>
                <th>SITE URL</th>
                <th>SITE PARAMETER</th>
                <th>LOCATION</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>US1</td>
                <td>https://app.datadoghq.com</td>
                <td>app.datadoghq.com</td>
                <td>US</td>
              </tr>
              <tr>
                <td>US3</td>
                <td>https://us3.datadoghq.com</td>
                <td>us3.datadoghq.com</td>
                <td>US</td>
              </tr>
              <tr>
                <td>US5</td>
                <td>https://us5.datadoghq.com</td>
                <td>us5.datadoghq.com</td>
                <td>US</td>
              </tr>
              <tr>
                <td>EU1</td>
                <td>https://app.datadoghq.eu</td>
                <td>datadoghq.eu</td>
                <td>EU (Germany)</td>
              </tr>
              <tr>
                <td>US1-FED</td>
                <td>https://app.ddog-gov.com</td>
                <td>ddog-gov.com</td>
                <td>US</td>
              </tr>
              <tr>
                <td>AP1</td>
                <td>https://ap1.datadoghq.com</td>
                <td>ap1.datadoghq.com</td>
                <td>Japan</td>
              </tr>
            </tbody>
          </table>
        </div>
      </Popover.Body>
    </Popover>
);

// Add this constant export
export const datadogApiKeyPopover = (props) => (
    <Popover id="datadogApiKeyPopover" style={{ maxWidth: '800px' }} {...props}>
      <Popover.Header as="h3">Datadog API Key</Popover.Header>
      <Popover.Body>
        <p style={{fontSize: "0.9em"}}>To add a Datadog API key:</p>
        <ol style={{fontSize: "0.9em"}}>
          <li>Navigate to Organization settings, then click the <strong>API keys</strong> tab</li>
          <li>Click the <strong>New Key</strong> button</li>
          <li>Enter a name for your key</li>
          <li>Click <strong>Create API key</strong></li>
        </ol>
        <p>
          <a href="https://docs.datadoghq.com/account_management/api-app-keys/#add-an-api-key-or-client-token" 
             target="_blank" 
             rel="noopener noreferrer">
            Learn more about API keys in Datadog docs →
          </a>
        </p>
      </Popover.Body>
    </Popover>
);

// Add this constant export
export const datadogAppKeyPopover = (
  <Popover id="datadogAppKeyPopover" style={{ maxWidth: '800px' }}>
    <Popover.Header as="h3">Datadog Application Key</Popover.Header>
    <Popover.Body>
      <p style={{fontSize: "0.9em"}}>To add a Datadog Application key:</p>
      <ol style={{fontSize: "0.9em"}}>
        <li>Navigate to <strong>Organization Settings</strong> &gt; <strong>Application Keys</strong></li>
        <li>Click the <strong>New Key</strong> button</li>
        <li>Enter a name for your key</li>
        <li>Click <strong>Create Application Key</strong></li>
      </ol>
      <p>
        <a href="https://docs.datadoghq.com/account_management/api-app-keys/#add-application-keys" 
           target="_blank" 
           rel="noopener noreferrer">
          Learn more about Application keys in Datadog docs →
        </a>
      </p>
    </Popover.Body>
  </Popover>
);

export const awsAccessKeyIdPopover = (
  <Popover id="awsAccessKeyIdPopover" style={{ maxWidth: '800px' }}>
    <Popover.Body>
      <p style={{fontSize: "0.9em"}}>For instructions on creating AWS access keys, please see this <a href="https://youtu.be/2V2ZF6tuOMY" target="_blank" rel="noreferrer">video</a></p>
    </Popover.Body>
  </Popover>
);

export const awsLogsQueryPopover = (
  <Popover id="awsLogsQueryPopover" style={{ maxWidth: '800px' }}>
    <Popover.Body>
      <p style={{fontSize: "0.9em"}}>Example:</p>
      <ul style={{fontSize: "0.9em"}}>
        <li><code>fields @timestamp, @message, @logStream, @log | sort @timestamp desc | filter @logStream like /frontend/</code><br/>
        </li>
        <li><code>*</code><br/>
        </li>
      </ul>
      <p style={{fontSize: "0.9em"}}>
        <a href="https://docs.aws.amazon.com/AmazonCloudWatch/latest/logs/CWL_QuerySyntax-examples.html#CWL_QuerySyntax-examples-general" 
           target="_blank" 
           rel="noopener noreferrer">
          View more query examples in AWS docs →
        </a>
      </p>
    </Popover.Body>
  </Popover>
);

export const gcpLogsQueryPopover = (
  <Popover id="gcpLogsQueryPopover" style={{ maxWidth: '800px' }}>
    <Popover.Body>
      <p style={{fontSize: "0.9em"}}>Example:</p>
      <ul style={{fontSize: "0.9em"}}>
        <li><code>resource.type="gce_instance" AND resource.labels.instance_id="1234567890" AND resource.labels.zone="us-central1-f"</code></li>
        <li><code>resource.type="cloud_run_revision" AND resource.labels.service_name="frontend"</code></li>
      </ul>
      <p style={{fontSize: "0.9em"}}>
        <a href="https://cloud.google.com/logging/docs/view/logging-query-language" 
           target="_blank" 
           rel="noopener noreferrer">
          View more query examples in GCP docs →
        </a>
      </p>
    </Popover.Body>
  </Popover>
);

export const datadogLogsQueryPopover = (
  <Popover id="datadogLogsQueryPopover" style={{ maxWidth: '800px' }}>
    <Popover.Body>
      <p style={{fontSize: "0.9em"}}>Example:</p>
      <ul style={{fontSize: "0.9em"}}>
        <li><code>service:frontend</code></li>
        <li><code>service:frontend AND env:production</code></li>
      </ul>
      <p style={{fontSize: "0.9em"}}>
        <a href="https://docs.datadoghq.com/logs/explorer/search_syntax/" 
           target="_blank" 
           rel="noopener noreferrer">
          View more query examples in Datadog docs →
        </a>
      </p>
    </Popover.Body>
  </Popover>
);

export const metricNamePopover = (
  <Popover id="awsMetricNamePopover" style={{ maxWidth: '800px' }}>
    <Popover.Body>
      <p style={{fontSize: "0.9em"}}>Relevant metrics that you would like to investigated</p>
    </Popover.Body>
  </Popover>
);