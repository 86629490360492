import React, { useState, useEffect, useRef } from 'react';
import { 
  Bell, Shield, Activity, Server, Database, Clock, Filter,
  ChevronDown, CheckCircle, XCircle, AlertTriangle, User, Link,
  Trash2, Menu, ChevronLeft, ChevronRight
} from 'lucide-react';
import { 
  Container, Card, Form, Row, Col, Badge, 
  Button, ButtonGroup, Spinner,
  Modal, Toast, ToastContainer, Dropdown
} from 'react-bootstrap';

import { makeAuthenticatedRequest } from '../../routes/utils';
import '../demo.css';
import { formatDateTime } from '../../routes/utils';
import { getPageNumbers } from '../utils';

const transformAlerts = (apiAlerts) => {
  return apiAlerts.map(alert => ({
    id: alert.id,
    title: alert.title,
    description: alert.description,
    severity: alert.severity.toLowerCase(),
    content: alert.content.join('\n'),
    source: alert.service_name,
    investigation_issue_id: alert.investigation_issue_id,
    time: formatDateTime(alert.created_at),
    status: alert.status.toLowerCase(),
    metrics: {
      component: alert.metadata?.component || '',
      event_type: alert.metadata?.event_type || '',
      //tags: alert.tags.join(', ') || ''
    },
    assignedTo: alert.metadata?.assignedTo || 'Unassigned',
    relatedAlerts: alert.alert_count - 1,
    timeline: [
      { time: formatDateTime(alert.created_at), event: 'Alert started', user: '' },
      ...(alert.last_occurrence && alert.last_occurrence !== alert.created_at ? [
        { time: formatDateTime(alert.last_occurrence), event: 'Alert last occurred', user: '' }
      ] : [])
    ]
  }));
};

const AlertCard = ({ alert, expandedAlertId, setExpandedAlertId, onStatusUpdate, clientSystemIdFromParams }) => {

    const severityConfig = {
        critical: { variant: 'danger', icon: AlertTriangle },
        high: { variant: 'warning', icon: Activity },
        medium: { variant: 'info', icon: Bell },
        low: { variant: 'primary', icon: Shield },
        info: { variant: 'secondary', icon: Bell }
      };
    
      const typeConfig = {
        security: { icon: Shield, label: 'Security' },
        performance: { icon: Activity, label: 'Performance' },
        infrastructure: { icon: Server, label: 'Infrastructure' },
        database: { icon: Database, label: 'Database' }
      };
    
      const statusConfig = {
        new: { variant: 'primary' },
        acknowledged: { variant: 'warning' },
        in_progress: { variant: 'info' },
        resolved: { variant: 'success' },
        dismissed: { variant: 'warning' }
    };

    const SeverityIcon = severityConfig[alert.severity].icon;
    const isExpanded = alert.id === expandedAlertId;

    // Add new state for content collapse
    const [isContentExpanded, setIsContentExpanded] = useState(false);

    // Add new state for loading
    const [isUpdating, setIsUpdating] = useState(false);

    const handleStatusUpdate = async (newStatus) => {
      setIsUpdating(true);
      try {
        const response = await makeAuthenticatedRequest(
          `/api/auto_monitor/alerts/${alert.id}/status`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ status: newStatus }),
          }
        );

        if (response.ok) {
          onStatusUpdate(); // Refresh alert list
        } else {
          const data = await response.json();
          throw new Error(data.error || 'Failed to update alert status');
        }
      } catch (error) {
        console.error('Error updating status:', error);
      } finally {
        setIsUpdating(false);
      }
    };

    return (
      <Card 
        key={alert.id}
        className={isExpanded ? 'border-primary' : ''}
        onClick={(e) => {
          e.stopPropagation();
          setExpandedAlertId(isExpanded ? null : alert.id);
        }}
      >
        <Card.Body>
          {/* Alert Header */}
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center gap-3">
              <SeverityIcon className={`text-${severityConfig[alert.severity].variant}`} size={20} />
              <small className="text-muted" style={{ fontFamily: 'monospace' }}>
                #{alert.id}
              </small>
              <span className="fw-semibold">{alert.title}</span>
              <Badge bg="secondary">
                {alert.source}
              </Badge>
              <Badge bg={statusConfig[alert.status].variant}>
                {alert.status}
              </Badge>
            </div>
            <div className="d-flex align-items-center gap-3">
              <small className="text-muted">{alert.time}</small>
              <Dropdown onClick={(e) => e.stopPropagation()}>
                <Dropdown.Toggle 
                  variant="link" 
                  className="text-muted p-0 border-0"
                  id={`dropdown-${alert.id}`}
                  disabled={isUpdating}
                >
                  <Menu size={18} />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item 
                    onClick={() => handleStatusUpdate('new')}
                    disabled={alert.status === 'new' || isUpdating}
                  >
                    <Clock size={16} className="me-2" color="blue" />
                    New
                  </Dropdown.Item>
                  <Dropdown.Item 
                    onClick={() => handleStatusUpdate('resolved')}
                    disabled={alert.status === 'resolved' || isUpdating}
                  >
                    <CheckCircle size={16} className="me-2" color="green" />
                    Resolve
                  </Dropdown.Item>
                  <Dropdown.Item 
                    onClick={() => handleStatusUpdate('dismissed')}
                    disabled={alert.status === 'dismissed' || isUpdating}
                  >
                    <XCircle size={16} className="me-2" color="orange" />
                    Dismiss
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <ChevronDown 
                style={{
                  transition: 'transform 0.2s ease-in-out',
                  transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)'
                }}
                size={20}
              />
            </div>
          </div>

          {/* Basic Info */}
          <Card.Text className="text-secondary mt-2">
            {alert.description}
          </Card.Text>

          {/* Expanded Content */}
          {isExpanded && (
            <div className="mt-4">
              {/* Add Content Section */}
              <div className="pb-3 mb-3">
                <h6 className="mb-2 text-muted">Content</h6>
                <div
                  className={`alert alert-secondary mb-0 ${
                    isContentExpanded ? '' : 'text-truncate'
                  }`}
                  style={{
                    maxHeight: isContentExpanded ? 'none' : '100px',
                    overflow: 'hidden',
                    whiteSpace: isContentExpanded ? 'pre-wrap' : 'pre',
                    fontFamily: 'monospace',
                    fontSize: '0.875rem',
                    cursor: 'pointer',
                    maxWidth: '1300px',
                    wordWrap: 'break-word',
                    wordBreak: 'break-all',
                    overflowWrap: 'break-word',
                    display: 'block'
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsContentExpanded(!isContentExpanded);
                  }}
                >
                  {alert.content}
                </div>
              </div>


              {/* Timeline */}
              <div className="border-top pt-3">
                <h4 className="fw-medium mb-3">Timeline</h4>
                <div className="d-flex flex-column gap-2">
                  {alert.timeline.map((event, index) => (
                    <div key={index} className="d-flex gap-2">
                      <div 
                        className="bg-secondary rounded-circle" 
                        style={{width: '6px', height: '6px', marginTop: '8px'}}
                      />
                      <div>
                        <div className="small">{event.event}</div>
                        <div className="text-muted" style={{fontSize: '0.75rem'}}>
                          {event.time}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {/* Action Buttons */}
              {alert.investigation_issue_id && (
                <div className="d-flex gap-2 pt-3 border-top mt-3">
                  <div className="d-flex gap-2">
                    {/* <Button variant="primary" size="sm">
                    In Progress
                  </Button>
                  <Button variant="success" size="sm">
                    Resolve
                  </Button>
                  <Button variant="outline-secondary" size="sm">
                    Dismiss
                  </Button> */}
                    <Button className="custom-btn"
                        href={`/dashboard?issue_id=${alert.investigation_issue_id}${
                          clientSystemIdFromParams ? `&client_system_id=${clientSystemIdFromParams}` : ''
                        }`}
                      >
                      View Investigation
                    </Button>
                  </div>
                </div>
              )}
            </div>
          )}
        </Card.Body>
      </Card>
    );
}

const AlertsDashboard = ({ clientSystemId, selectedAlertId, clientSystemIdFromParams }) => {
  const [selectedSeverity, setSelectedSeverity] = useState('>=high');
  const [selectedService, setSelectedService] = useState('all');
  const [expandedAlertId, setExpandedAlertId] = useState(null);
  const [alerts, setAlerts] = useState([]);
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const alertsContainerRef = useRef(null);
  const [selectedStatus, setSelectedStatus] = useState('new');

  // Add pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalAlerts, setTotalAlerts] = useState(0);
  const alertsPerPage = 20;

  const fetchAlerts = async () => {
    setLoading(true);
    try {
      const response = await makeAuthenticatedRequest(
        `/api/auto_monitor/alerts?client_system_id=${clientSystemId}&page=${currentPage}&per_page=${alertsPerPage}`
      );
      const data = await response.json();
      const transformedAlerts = transformAlerts(data.alerts);
      setAlerts(transformedAlerts);
      setTotalPages(data.pages);
      setTotalAlerts(data.total);
      
      // Extract unique services
      const uniqueServices = [...new Set(transformedAlerts.map(alert => alert.source))];
      setServices(uniqueServices);
    } catch (error) {
      console.error('Error fetching alerts:', error);
    } finally {
      setLoading(false);
    }
  };

  // Update useEffect to include currentPage dependency
  useEffect(() => {
    if (!clientSystemId) return;
    fetchAlerts();
  }, [clientSystemId, currentPage]);

  // Add pagination handler
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    // Scroll to top of alerts container when page changes
    if (alertsContainerRef.current) {
      alertsContainerRef.current.scrollTop = 0;
    }
  };

  const isSeverityMatch = (alert, selectedSeverity) => {
    if (selectedSeverity === 'all') {
      return true;
    }
    if (selectedSeverity === '>=critical'){
      return alert.severity === 'critical';
    } else if (selectedSeverity === '>=high'){
      return (alert.severity === 'critical' || alert.severity === 'high');
    } else if (selectedSeverity === '>=medium'){
      return (alert.severity === 'critical' || alert.severity === 'high' || alert.severity === 'medium');
    } else if (selectedSeverity === '>=low'){
      return (alert.severity === 'critical' || alert.severity === 'high' || alert.severity === 'medium' || alert.severity === 'low');
    }
  }

  // Add filtered alerts logic
  const filteredAlerts = alerts.filter(alert => {
    const matchesSeverity = isSeverityMatch(alert, selectedSeverity);
    const matchesService = selectedService === 'all' || alert.source === selectedService;
    const matchesStatus = selectedStatus === 'all' || alert.status === selectedStatus;
    return matchesSeverity && matchesService && matchesStatus;
  });

  const handleDelete = async () => {
    setIsDeleting(true);
    try {
      const url = `/api/auto_monitor/alerts?client_system_id=${clientSystemId}${
        selectedService !== 'all' ? `&service_name=${selectedService}` : ''
      }`;
      
      const response = await makeAuthenticatedRequest(url, {
        method: 'DELETE'
      });
      const data = await response.json();
      
      setDeleteMessage(`Successfully deleted ${data.deleted_count} alerts`);
      setShowToast(true);
      
      // Reuse the fetch alerts function
      await fetchAlerts();
    } catch (error) {
      console.error('Error deleting alerts:', error);
      setDeleteMessage('Error deleting alerts');
      setShowToast(true);
    } finally {
      setIsDeleting(false);
      setShowDeleteModal(false);
    }
  };

  // Modify this effect to wait for alerts to load
  useEffect(() => {
    if (selectedAlertId && alerts.length > 0) {
      setExpandedAlertId(Number(selectedAlertId));
      // Add a small delay to ensure the alert card is expanded before scrolling
      setTimeout(() => {
        const alertElement = document.getElementById(`alert-${selectedAlertId}`);
        if (alertElement) {
          alertElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }, 100);
    }
  }, [selectedAlertId, alerts]);

  return (
    <Container fluid className="py-4 bg-light d-flex flex-column" style={{ height: 'calc(100vh - 200px)' }}>
      {/* Header with Filters - Fixed */}
      <div className="mb-4">
        <div className="d-flex justify-content-between align-items-center">
          <h1 className="h3">Recent Alerts</h1>
          <div className="d-flex gap-3 align-items-center">
            {/* Add Severity Filter */}
            <Form.Select
              value={selectedSeverity}
              onChange={(e) => setSelectedSeverity(e.target.value)}
            >
              <option value="all">All Severity</option>
              <option value=">=critical">&gt;= Critical</option>
              <option value=">=high">&gt;= High</option>
              <option value=">=medium">&gt;= Medium</option>
              <option value=">=low">&gt;= Low</option>
            </Form.Select>

            {/* Existing Status Filter */}
            <Form.Select
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(e.target.value)}
            >
              <option value="all">All Status</option>
              <option value="new">New</option>
              <option value="resolved">Resolved</option>
              <option value="dismissed">Dismissed</option>
            </Form.Select>
            
            {/* Existing Service Filter */}
            <Form.Select
              value={selectedService}
              onChange={(e) => setSelectedService(e.target.value)}
              style={{ minWidth: '140px' }}
            >
              <option value="all">All Services</option>
              {services.map(service => (
                <option key={service} value={service}>{service}</option>
              ))}
            </Form.Select>

            <Button 
              variant="outline-danger"
              onClick={() => setShowDeleteModal(true)}
              disabled={isDeleting}
            >
              <Trash2 size={18} />
            </Button>
          </div>
        </div>
      </div>

      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete {selectedService === 'all' ? 'all' : selectedService} alerts?
          This action cannot be undone.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button 
            variant="danger" 
            onClick={handleDelete}
            disabled={isDeleting}
          >
            {isDeleting ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="me-2"
                />
                Deleting...
              </>
            ) : (
              'Delete'
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Toast Notification */}
      <ToastContainer position="top-end" className="p-3">
        <Toast 
          show={showToast} 
          onClose={() => setShowToast(false)}
          delay={3000}
          autohide
        >
          <Toast.Header>
            <strong className="me-auto">Alert Management</strong>
          </Toast.Header>
          <Toast.Body>{deleteMessage}</Toast.Body>
        </Toast>
      </ToastContainer>

      {/* Scrollable alerts container */}
      <div ref={alertsContainerRef} className="flex-grow-1 overflow-auto">
        {loading ? (
          <div className="text-center py-5">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : filteredAlerts.length === 0 ? (
          <div className="text-center py-5">
            <Bell size={48} className="text-muted mb-3" />
            <h4>No alerts so far</h4>
            <p className="text-muted">Relvy is monitoring your system for any incidents</p>
          </div>
        ) : (
          <div className="d-flex flex-column gap-3">
            {filteredAlerts.map(alert => (
              <div id={`alert-${alert.id}`} key={alert.id}>
                <AlertCard 
                  alert={alert}
                  expandedAlertId={expandedAlertId}
                  setExpandedAlertId={setExpandedAlertId}
                  onStatusUpdate={fetchAlerts}
                  clientSystemIdFromParams={clientSystemIdFromParams}
                />
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Pagination moved outside scrollable container */}
      {!loading && filteredAlerts.length > 0 && (
        <div className="d-flex justify-content-between align-items-center mt-4">
          {/* <div className="text-muted">
            Showing {((currentPage - 1) * alertsPerPage) + 1} to {Math.min(currentPage * alertsPerPage, totalAlerts)} of {totalAlerts} alerts with severity {selectedSeverity}
          </div> */}
          <div className="d-flex gap-2 align-items-center">
            <Button
              variant="outline-secondary"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1 || loading}
              className="d-flex align-items-center justify-content-center"
              style={{ width: '32px', height: '32px', padding: 0 }}
            >
              <ChevronLeft size={20} />
            </Button>

            {getPageNumbers(currentPage, totalPages).map((pageNum, index) => (
              pageNum === '...' ? (
                <span key={`dots-${index}`} className="px-2">...</span>
              ) : (
                <Button
                  key={pageNum}
                  variant={currentPage === pageNum ? "primary" : "outline-secondary"}
                  onClick={() => handlePageChange(pageNum)}
                  disabled={loading}
                  style={{
                    width: '32px',
                    height: '32px',
                    padding: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  {pageNum}
                </Button>
              )
            ))}

            <Button
              variant="outline-secondary"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages || loading}
              className="d-flex align-items-center justify-content-center"
              style={{ width: '32px', height: '32px', padding: 0 }}
            >
              <ChevronRight size={20} />
            </Button>
          </div>
        </div>
      )}
    </Container>
  );
};

export default AlertsDashboard;
