import { createRef, useEffect, useMemo, useState } from 'react';
import { Accordion, Container, Row, Col, Card, Spinner, Tabs, Tab, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import ReactDiffViewer from 'react-diff-viewer-continued';

import {CustomPlaceholder, ParagraphPlaceholder} from '../routes/issue_details_components';
import {Attachment, AttachmentModal, Breadcrumb, CommentsCard} from '../routes/issue_details_components';
import {createDisplayStepFromLogAnalysisData, createDisplayStepFromMetricsAnalysisData} from '../routes/utils';

import MetricView from './metrics';
import LogsView from './logs';

import {IoThumbsDownOutline, IoThumbsUpOutline, IoThumbsDown, IoThumbsUp} from 'react-icons/io5';

import './demo.css';
import './css/issue_details.css';

import {makeAuthenticatedRequest} from '../routes/utils';

const AnalysisTab = ({ issue, analysisData, onRunAnalysis, onUserPrompt, onInterrupt, selectIssueFromIdHandler }) => (
  true ? (
    <div>
      <IssueDataView issue={issue} />
      <AnalysisDataView issue={issue} analysisData={analysisData} onRunAnalysis={onRunAnalysis} onUserPrompt={onUserPrompt} onInterrupt={onInterrupt} selectIssueFromIdHandler={selectIssueFromIdHandler} />
    </div>
  ) : (
    <div className="text-center py-4">
      <Spinner animation="border" role="status" variant="purple">
        <span className="visually-hidden">Loading analysis...</span>
      </Spinner>
    </div>
  )
);


const LogAnalysisStepView = (props) => {
  let stepTitle = `Analyzing logs from service '${props.analysisStepData['file_analyzed']}'`;
  let stepTitleComponents = {
    'action': 'logs',
    'service': props.analysisStepData['file_analyzed'],
    'stepIndx': props.stepIndx,
  };
  return (
		<LogsView 
			logData={props.analysisStepData.observation.logLines} 
			highlight={props.analysisStepData.observation.highlight} 
			stepTitleComponents={stepTitleComponents}
			stepReason={props.analysisStepData.reason} 
			query={props.analysisStepData.query} 
			stepPostscript={props.analysisStepData.observation.relevance} 
		/>
  );
};

const MetricAnalysisStepView = (props) => {
  let stepTitle = `Analyzing metric '${props.analysisStepData['metric_analyzed']}' from service '${props.analysisStepData['service_analyzed']}'`;
  let metricData = props.analysisStepData.metric ? [props.analysisStepData.metric] : [];
  let stepTitleComponents = {
    'action': 'metrics',
    'metric': props.analysisStepData['metric_analyzed'],
    'service': props.analysisStepData['service_analyzed'],
    'stepIndx': props.stepIndx,
  };

  return (
		<MetricView 
			metricData={metricData} 
			stepTitleComponents={stepTitleComponents}
			stepReason={props.analysisStepData.reason} 
			stepPostscript={props.analysisStepData.summary} 
			query={props.analysisStepData.query} 
		/>
  );
};

const SimilarIssues = ({ analysisData, selectIssueFromIdHandler }) => {
  return (
    <Card className="mb-3">
      <Card.Header className="card-header-custom"><strong>Previous Similar Issues</strong></Card.Header>
      <Card.Body>
        <div className="similar-issues-list">
          {analysisData.relatedIssueData.map((issue) => (
            <div 
              key={issue.id} 
              className="issue-item py-2"
              style={{ borderBottom: '1px solid rgba(0,0,0,0.05)' }}
            >
              <button
                onClick={() => selectIssueFromIdHandler(issue.id)}
                className="issue-summary text-start btn btn-link p-0"
                style={{
                  maxWidth: '97%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  textDecoration: 'none',
                  color: '#2c3e50',
                  fontSize: '0.95rem',
                }}
                onMouseOver={(e) => e.target.style.color = '#5935c9'}
                onMouseOut={(e) => e.target.style.color = '#2c3e50'}
              >
                {issue.summary}
              </button>
            </div>
          ))}
        </div>
      </Card.Body>
    </Card>
  );
};

const RequestingInfo = (props) => {
  const [idValues, setIdValues] = useState([]);

  useEffect(() => {
    setIdValues(props.analysisData.identifiersToAskFor.map(() => ''));
  }, []);

  const handleInputChange = (index) => (event) => {
    const newIdValues = [...idValues];
    newIdValues[index] = event.target.value;
    setIdValues(newIdValues);
  }


  const onSubmit = () => {
    var userPrompt = props.analysisData.identifiersToAskFor[0] + ": " + idValues[0];
    for (var indx=1; indx < idValues.length; indx++) {
      if (idValues[indx].trim().length === 0) {
        continue;
      }
      userPrompt += ", " + props.analysisData.identifiersToAskFor[indx] + ": " + idValues[indx];
    }
    props.onUserPrompt(userPrompt);
  }


  return (
    <Card className="mb-3">
      <Card.Body>
        <Card.Title>Requesting Information</Card.Title>
        <Card.Text>
          <div className="row">
            <div className="col-md-12">
              <p>We think the following information would be helpful to diagnose the issue. Please provide if you can:</p>
            </div>
          </div>
            {props.analysisData.identifiersToAskFor.map((identifier, index) => (
              <div className="row my-2" key={index}>
                <div className="col-md-3">
                  {identifier}
                </div>
                <div className="col-md-9">
                  <input type="text" className="form-control"
                  onChange={handleInputChange(index)}
                  value={idValues[index]} />
                </div>
              </div>
            ))}
              <div className="row my-3">
                <div className="col-md-2">
                  <button 
                    type="submit" 
                    className="btn btn-primary custom-btn m-0"
                    style={{
                      backgroundColor: '#5935c9',
                      borderColor: '#5935c9',
                      color: 'white',
                      transition: 'background-color 0.3s, color 0.3s'
                    }}
                    onClick={onSubmit}
                  >
                    Submit
                  </button>
                </div>
              </div>
        </Card.Text>
      </Card.Body>
    </Card>
    );
};


const AnalysisSteps = (props) => {
  const getStepTitle = (step, index) => {
    if (step['step_type'] === 'ongoing') {
      return (
        <>
          <span className="step-number">Step {index + 1}</span>
          <span className="highlight-ongoing">Currently running</span>: {step['currentNextStep']}
        </>
      );
    } else if (step['step_type'] === 'user_input') {
      return (
        <>
          <span className="step-number">Step {index + 1}</span>
          <span className="highlight-logs">User Suggestion</span>
          <code className="service-name">{step['message']}</code>
        </>
      );
    } else if (step['step_type'] === 'log_analysis') {
      return (
        <>
          <span className="step-number">Step {index + 1}</span>Analyzing
          {' '}
          <span className="highlight-logs">logs</span>
          {' '}
          from service
          {' '}
          <code className="service-name" style={{ maxWidth: '150px' }}>{step['file_analyzed']}</code>
        </>
      );
    } else {
      return (
        <>
          <span className="step-number">Step {index + 1}</span>Analyzing
          {' '}
          <span className="highlight-logs">metric</span>
          {' '}
          <code className="service-name" style={{ maxWidth: '400px' }}>{step['metric_analyzed']}</code>
          {' '}
          from service
          {' '}
          <code className="service-name" style={{ maxWidth: '150px' }}>{step['service_analyzed']}</code>
        </>
      );
    }
  };

  const scrollRef = useMemo(() => createRef(), []);

  useEffect(() => {
    if (props.analysisData.stillRunning && scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return (
    props.analysisData && props.analysisData.analysisSteps.length > 0 && (
    <Card className="my-3 analysis-steps">
      <Card.Header className="card-header-custom"><strong>Step-by-Step Root Cause Analysis</strong></Card.Header>
      <Card.Body>
        <Accordion alwaysOpen>
          {props.analysisData.analysisSteps.map((step, index) => (
            <Accordion.Item key={index} eventKey={index.toString()} id={'step-' + (index+1)}>
              <Accordion.Header>
              {getStepTitle(step, index)}
                {/* Step {index + 1}: {step['step_type'] === 'log_analysis' ? 'Log Analysis' : 'Metric Analysis'} */}
              </Accordion.Header>
              <Accordion.Body>
                {step['step_type'] === 'log_analysis' && <LogAnalysisStepView stepIndx={index + 1} analysisStepData={step} />}
                {step['step_type'] === 'metrics_analysis' && <MetricAnalysisStepView stepIndx={index + 1} analysisStepData={step} />}
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </Card.Body>
      <div ref={scrollRef} />
    </Card>
  )
  );
};

const FeedbackButtons = ({ feedbackState, handleFeedback }) => {
  const feedbackOptions = [
    { tooltip: "Good response", icon: IoThumbsUp, outlineIcon: IoThumbsUpOutline, value: 1 },
    { tooltip: "Bad response", icon: IoThumbsDown, outlineIcon: IoThumbsDownOutline, value: -1 },
  ];

  return (
    <div className="mt-3 d-flex justify-content-end">
      {feedbackOptions.map(({ tooltip, icon: Icon, outlineIcon: OutlineIcon, value }) => (
        <OverlayTrigger
          key={value}
          placement="top"
          overlay={<Tooltip id={`tooltip-${value}`}>{tooltip}</Tooltip>}
        >
          <Button
            variant="outline-dark"
            size="sm"
            className={value === 1 ? "me-1" : ""}
            onClick={() => handleFeedback(value)}
          >
            {feedbackState === value ? <Icon /> : <OutlineIcon />}
          </Button>
        </OverlayTrigger>
      ))}
    </div>
  );
};

const GithubCommitInfo = ({ commitInfo }) => {
  let [relevantChanges, setRelevantChanges] = useState([]);

  useEffect(() => {
    if (!commitInfo || !commitInfo.relevant_changes) {
      return;
    }

    let changes = commitInfo.relevant_changes.map((change) => {
      let patch = change.patch_diff || '';
      let lines = patch.split('\n');
      let diffData = lines.map(line => {
        if (line.startsWith('-')) {
          return {
            type: 'delete',
            content: line.substring(1),
            lineNumbers: ['1', null] // You might want to implement proper line numbering
          };
        } else if (line.startsWith('+')) {
          return {
            type: 'add',
            content: line.substring(1),
            lineNumbers: [null, '1'] // You might want to implement proper line numbering
          };
        } else {
          return {
            type: 'context',
            content: line,
            lineNumbers: ['1', '1'] // You might want to implement proper line numbering
          };
        }
      });

      return {
        file_name: change.file_name,
        diffData: diffData,
        commit_url: 'https://github.com/' + change.repo + '/commit/' + change['commit_sha'],
      };
    });

    let changesByCommitUrl = new Map();
    for (let change of changes) {
      if (!changesByCommitUrl.has(change.commit_url)) {
        changesByCommitUrl.set(change.commit_url, []);
      }
      changesByCommitUrl.get(change.commit_url).push(change);
    }

    changes = [];
    for (let [commitUrl, commitChanges] of changesByCommitUrl) {
      changes.push({
        commit_url: commitUrl,
        changes: commitChanges,
      });
    }

    setRelevantChanges(changes);
  }, [commitInfo]);

  if (relevantChanges.length === 0) {
    return null;
  }

  return (
    <div className="github-commit-info mt-3">
      <div className="insight-container mb-3"
        style={{
          position: 'relative',
          backgroundColor: '#f8f9fa',
          borderRadius: '8px',
          padding: '12px 16px',
          boxShadow: '0 1px 3px rgba(0,0,0,0.05)',
        }}
      >
        <div className="d-flex justify-content-between align-items-start">
          <span className="insight-text">{commitInfo.conclusion}</span>
        </div>
        <div className="insight-accent-line" />
      </div>

      <div className="mx-3">
        {relevantChanges.map((changeData, index) => (
          <div className="mb-3" key={index}>
            {changeData.changes.map((change, changeIndex) => (
              <div key={changeIndex} className="card mb-3">
                <div className="card-header bg-light py-2 px-3 d-flex justify-content-between align-items-center">
                  <code className="text-muted">{change.file_name}</code>
                  <a 
                    href={changeData.commit_url} 
                    target="_blank" 
                    rel="noreferrer"
                    className="badge bg-light text-primary border border-primary-subtle ms-2 px-2 py-1"
                    style={{ 
                      textDecoration: 'none',
                      fontSize: '0.85rem',
                      transition: 'all 0.2s ease',
                    }}
                    onMouseOver={(e) => e.target.style.backgroundColor = '#e8f0fe'}
                    onMouseOut={(e) => e.target.style.backgroundColor = '#fff'}
                  >
                    View Commit
                  </a>
                </div>
                <div className="card-body p-0">
                  <div className="diff-viewer">
                    {change.diffData.map((line, lineIndex) => (
                      <div
                        key={lineIndex}
                        className={`d-flex diff-line ${
                          line.type === 'add' ? 'diff-line-add' :
                          line.type === 'delete' ? 'diff-line-delete' : ''
                        }`}
                      >
                        <div className="d-flex border-end text-muted bg-light user-select-none">
                          <div className="px-2 text-end border-end" style={{width: '4em'}}>
                            {line.lineNumbers[0] || ' '}
                          </div>
                          <div className="px-2 text-end" style={{width: '4em'}}>
                            {line.lineNumbers[1] || ' '}
                          </div>
                        </div>
                        <div className="flex-grow-1 px-2" style={{whiteSpace: 'pre'}}>
                          <span className="d-inline-block" style={{width: '1em'}}>
                            {line.type === 'add' && <span className="diff-add">+</span>}
                            {line.type === 'delete' && <span className="diff-delete">-</span>}
                          </span>
                          {line.content}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};


const InitialAssessment = ({ initialAssessment }) => {
  let sections = []
  for (let qa of initialAssessment) {
    sections.push({text: qa.answer});
  }

  return (
    <div className="readable-root-cause">
      {sections.length > 0 && (
        <div className="d-flex flex-column gap-3">
          {sections.map((section, index) => {
            if (typeof section.text !== 'string') return null;
            
            return (
              <div 
                key={index} 
                className="insight-container"
                style={{
                  position: 'relative',
                  backgroundColor: '#f8f9fa',
                  borderRadius: '8px',
                  padding: '12px 16px',
                  boxShadow: '0 1px 3px rgba(0,0,0,0.05)',
                }}
              >
                <div className="d-flex justify-content-between align-items-start gap-3">
                  <div className="insight-text">
                    {section.text}
                  </div>
                </div>
                <div className="insight-accent-line" />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};


const ReadableRootCause = ({ rootCause, githubCommitInfo, referenceClickHandler }) => {
  const sections = rootCause?.sections || [];
  
  return (
    <div className="readable-root-cause">
      {rootCause && sections.length > 0 && (
        <div className="d-flex flex-column gap-3">
          {sections.map((section, index) => {
            if (typeof section.text !== 'string') return null;
            
            return (
              <div 
                key={index} 
                className="insight-container"
                style={{
                  position: 'relative',
                  backgroundColor: '#f8f9fa',
                  borderRadius: '8px',
                  padding: '12px 16px',
                  boxShadow: '0 1px 3px rgba(0,0,0,0.05)',
                }}
              >
                <div className="d-flex justify-content-between align-items-start gap-3">
                  <div className="insight-text">
                    {section.text}
                  </div>
                  {section.references && Array.isArray(section.references) && (
                    <div className="insight-references">
                      {section.references.map((ref, refIndex) => (
                        <button
                          key={refIndex}
                          onClick={() => referenceClickHandler(ref)}
                          className="badge bg-light text-primary border border-primary-subtle mx-1 px-2 py-1"
                          onMouseOver={(e) => e.target.style.backgroundColor = '#e8f0fe'}
                          onMouseOut={(e) => e.target.style.backgroundColor = '#fff'}
                        >
                          #{ref}
                        </button>
                      ))}
                    </div>
                  )}
                </div>
                <div className="insight-accent-line" />
              </div>
            );
          })}
        </div>
      )}
      {githubCommitInfo && <GithubCommitInfo commitInfo={githubCommitInfo} />}
    </div>
  );
};

const AnalysisContent = ({ issue, analysisData, scrollToStepIndx }) => {
  const [feedbackState, setFeedbackState] = useState(null);

  useEffect(() => {
    if (analysisData?.feedbackScore === 1) {
      setFeedbackState(1);
    } else if (analysisData?.feedbackScore === -1) {
      setFeedbackState(-1);
    } else {
      setFeedbackState(null);
    }
  }, [analysisData?.feedbackScore]);

  const handleFeedback = async (score) => {
    try {
      const response = await makeAuthenticatedRequest(`/api/dashboard/v2/issues/${issue.id}/feedback`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ feedback_score: score }),
      });

      if (response.ok) {
        setFeedbackState(score);
      } else {
        console.error("Failed to submit feedback");
        console.log(response);
      }
    } catch (error) {
      console.error("Error submitting feedback:", error);
    }
  };

  const getCardContent = () => {
    // Case 1: User message response
    if (analysisData?.nextStep === 'response') {
      return {
        title: 'Relvy AI',
        body: <Card.Text>{analysisData.nextStepReason}</Card.Text>
      };
    }

    // Case 2: No data available yet
    const hasAnyData = analysisData?.rootCause || 
                      analysisData?.possibleRootCauseSoFar || 
                      analysisData?.githubCommitInfo || analysisData?.initialAssessment;

    if (!hasAnyData) {
      return {
        title: <strong>Analysis in Progress</strong>,
        body: (
          <Card.Text>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Card.Text>
        )
      };
    }

    // Case 3: Root cause found
    if (analysisData.rootCause) {
      const body = (
        <>
          {analysisData.initialAssessment && (
            <InitialAssessment initialAssessment={analysisData.initialAssessment} />
          )}
          {analysisData.readableRootCause ? (
            <ReadableRootCause 
              rootCause={analysisData.readableRootCause} 
              githubCommitInfo={analysisData.githubCommitInfo} 
              referenceClickHandler={scrollToStepIndx} 
            />
          ) : (
            <Card.Text>{analysisData.rootCause}</Card.Text>
          )}
          {/* {analysisData.nextStep && analysisData.nextStep !== "root_cause" && (
            <div className="mt-3">
              <div className="mb-2 border-bottom pb-2">
                <strong>Recommended Action:</strong>
              </div>
              <p style={{ fontSize: '0.9rem' }}>{analysisData.nextStep}</p>
              <p style={{ fontSize: '0.9rem' }}>{analysisData.nextStepReason}</p>
            </div>
          )} */}
        </>
      );
      return {
        title: <strong>Possible Root Cause</strong>,
        body: body,
      };
    }

    // Case 4: Partial analysis results
    const rootCauseContent = <>
      {analysisData.initialAssessment && (
        <InitialAssessment initialAssessment={analysisData.initialAssessment} />
      )}
      {analysisData.possibleRootCauseSoFar ? (
        analysisData.readableRootCauseSoFar ? (
          <ReadableRootCause 
            rootCause={analysisData.readableRootCauseSoFar} 
            githubCommitInfo={analysisData.githubCommitInfo} 
            referenceClickHandler={scrollToStepIndx} 
          />
        ) : (
          <Card.Text>{analysisData.possibleRootCauseSoFar}</Card.Text>
        )
      ) : (
        // Case 5: Only GitHub commit info
        analysisData.githubCommitInfo ? (
          <ReadableRootCause 
            rootCause={null}
            githubCommitInfo={analysisData.githubCommitInfo} 
            referenceClickHandler={scrollToStepIndx} 
          />
        ) : null
      )}
    </>;
    
    return {
      title: <strong>Analysis Summary, so far</strong>,
      body: rootCauseContent
    };
  };

  const content = getCardContent();
  
  return (
    <Card className="mb-3">
      <Card.Header className="card-header-custom">{content.title}</Card.Header>  
      <Card.Body>
        {content.body}
        {!analysisData.stillRunning && (
          <FeedbackButtons
            feedbackState={feedbackState}
            handleFeedback={handleFeedback}
          />
        )}
      </Card.Body>
    </Card>
  );
};

const AnalysisDataView = (props) => {
  const [userHint, setUserHint] = useState('');
  const [isInterrupting, setIsInterrupting] = useState(false);
  const [isPrompting, setIsPrompting] = useState(false);

  useEffect(() => {
    if (props.analysisData && props.analysisData.state === 'INTERRUPTING') {
      setIsInterrupting(true);
    } else {
      setIsInterrupting(false);
    }

  }, [props.analysisData]);

  const handleInterrupt = () => {
    setIsInterrupting(true);
    props.onInterrupt().then(() => {
      //setIsInterrupting(false);
    }).catch(() => {
      setIsInterrupting(false);
    });
  };

  const handlePrompt = () => {
    setIsPrompting(true);
    const result = props.onUserPrompt(userHint);
    Promise.resolve(result)
      .then(() => {
        setIsPrompting(false);
        setUserHint("");
      })
      .catch((error) => {
        console.error("Error in onUserPrompt:", error);
        setIsPrompting(false);
      });
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handlePrompt();
    }
  };

  const scrollToStepIndx = (stepIndx) => {
    const element = document.getElementById(`step-${stepIndx}`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  if (!props.analysisData) {
    return (
      <div>
        <Button
            variant="success"
            type="submit"
            className="align-self-end" onClick={props.onRunAnalysis}>Run Analysis</Button>
      </div>
      );
  }

  return (
    <div>
      <AnalysisContent
        issue={props.issue}
        analysisData={props.analysisData} 
        scrollToStepIndx={scrollToStepIndx}
      />
      {/* {!props.analysisData.stillRunning && (
        <FeedbackButtons
          feedbackState={feedbackState}
          handleFeedback={handleFeedback}
        />
      )} */}

    {props.analysisData && props.analysisData.relatedIssueData && props.analysisData.relatedIssueData.length > 0 && (<SimilarIssues analysisData={props.analysisData} selectIssueFromIdHandler={props.selectIssueFromIdHandler} />)}
    {/* {props.analysisData && props.analysisData.identifiersToAskFor && props.analysisData.identifiersToAskFor.length > 0 && (
      <>
      <RequestingInfo analysisData={props.analysisData} onUserPrompt={props.onUserPrompt} />
    </>)
    } */}

    <AnalysisSteps analysisData={props.analysisData} />

    {/* Spinner for ongoing analysis */}
    {props.analysisData.stillRunning && (<div className="d-flex justify-content-between align-items-center mt-3">
      <div className="flex-grow-1">
        {props.analysisData.state === 'IN_PROGRESS' && (
          <div className="d-flex align-items-center">
            <Spinner animation="border" role="status" size="sm">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
            <span style={{ color: '#5935c9', marginLeft: '8px' }} className="ms-2">Relvy working its magic....</span>
          </div>
        )}
      </div>
      <button 
        className="btn btn-outline-danger ms-3" 
        onClick={handleInterrupt}
        disabled={isInterrupting}
      >
        {isInterrupting ? (
          <>
            <Spinner animation="border" size="sm" className="me-2" />
            Stopping...
          </>
        ) : (
          'Stop Analysis'
        )}
      </button>
    </div>)}

    {/* Prompt for user input */}
    {true && (
      <div className="card my-3">
        <div className="row mx-3 my-3">
          <div className="col-md-11">
            <input 
              type="text" 
              placeholder="Ask Relvy to look at other logs or metrics..." 
              className="form-control" 
              id="alsoLookAt" 
              value={userHint} 
              onChange={(e) => setUserHint(e.target.value)}
              onKeyDown={handleKeyDown}
            />
          </div>
          <div className="col-md-1">
            <button 
              type="submit" 
              className="btn btn-primary custom-btn m-0"
              style={{
                backgroundColor: '#5935c9',
                borderColor: '#5935c9',
                color: 'white',
                transition: 'background-color 0.3s, color 0.3s'
              }}
              onClick={handlePrompt}
              disabled={isPrompting}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    )}
  </div>);
};

const IssueDataView = (props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  // Sanitize and format description if needed
  const createMarkup = (htmlContent) => {
    // You could add additional sanitization here if needed
    return { __html: htmlContent || '' };
  };

  return (
    <div>
      <Card className="mb-3">
        <Card.Header className="card-header-custom"><strong>Details</strong></Card.Header>
        <Card.Body 
          style={{
            fontSize: '0.9rem',
            maxHeight: isExpanded ? 'none' : '300px',
            overflow: 'hidden',
            cursor: 'pointer',
            paddingBottom: '2rem', // Add extra space at the bottom for the fade effect
            position: 'relative' // For absolute positioning of the fade overlay
          }}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <Card.Text>{props.issue.summary || '...'}</Card.Text>
          {props.issue.description && (
            <div 
              dangerouslySetInnerHTML={createMarkup(props.issue.description)}
              className="description-content"
            />
          )}
          
          {/* Ellipsis when content is truncated */}
          {!isExpanded && (
            <div 
              style={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                height: '40px',
                background: 'linear-gradient(transparent, white)',
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'center',
                paddingBottom: '8px'
              }}
            >
              <span style={{ color: '#666' }}>...</span>
            </div>
          )}
        </Card.Body>
      </Card>
    </div>
  );
}

const MainContent = ({ issue, analysisData, onRunAnalysis, onUserPrompt, onInterrupt, selectIssueFromIdHandler }) => {
  return (
    <div>
      <AnalysisTab issue={issue} analysisData={analysisData} onRunAnalysis={onRunAnalysis} onUserPrompt={onUserPrompt} onInterrupt={onInterrupt} selectIssueFromIdHandler={selectIssueFromIdHandler} />
    </div>
  );
};


const IssueDetailsPage = (props) => {
  const [issueData, setIssueData] = useState({
    summary: props.item.summary,
    details: props.item.description,
    relevant_files: null,
  });
  const [analysisData, setAnalysisData] = useState(null);

  const [failureMessage, setFailureMessage] = useState(null);
  const [pollerId, setPollerId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [numPollRequests, setNumPollRequests] = useState(0);

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';

  const processIssueData = (data) => {
    setIssueData((prevData) => ({
      ...prevData,
      ...data,
    }));

    processAnalysisData(data['analysis_results']);
  };

  const fetchIssueData = async (issueId) => {
    
    if (numPollRequests > 100) {
      return;
    }
    setNumPollRequests(numPollRequests + 1);
    const url = `/api/dashboard/v2/issues/${issueId}`;

    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    };

    let response = await makeAuthenticatedRequest(url, options);
    if (response.ok) {
      processIssueData(await response.json());
    } else {
      setFailureMessage("Failed to fetch data");
    }
  };

  const clearPollerIfExists = () => {
    clearInterval(pollerId);
  };

  useEffect(() => {
    clearPollerIfExists();
    setNumPollRequests(0);
    fetchIssueData(props.item.id);
    let newPollerId = setInterval(() => {
      fetchIssueData(props.item.id);
    }, 2000);
    setPollerId(newPollerId);

    return () => {
      clearInterval(newPollerId);
    }
  }, [props.item.id]);


  const getReadableNextStep = (nextStep, params) => {
    if (nextStep === 'request_logs') {
      return "Request logs from " + params["service"];
    } else if (nextStep === 'request_metrics') {
      return "Request metrics from " + params["service"] + ' - ' + params["metric_name"];
    } else if (nextStep === 'escalate') {
      return "Escalate to " + params['to'];
    } else {
      return nextStep;
    }
  }

  useEffect(() => {
    if (!analysisData){
      clearPollerIfExists();
      return;
    }

    if (analysisData && !analysisData.stillRunning) {
      clearPollerIfExists();
      props.refreshCallback(false);
    }
  }, [analysisData]);


  const processAnalysisData = (data) => {
    if (!data){
      setAnalysisData(null);
      return;
    }

    var output = {
      'identifiersToAskFor': data['identifiers_to_ask_for'],
      'relatedIssueData': data['related_issue_data'],
      'githubCommitInfo': data['github_commit_info'],
      'initialAssessment': data['initial_assessment'],
    };
    
    let finalResponse = data["final_response"];
    output['stillRunning'] = (data["state"] === "IN_PROGRESS" || data["state"] === "INTERRUPTING" || data["state"] === "NOT_STARTED");
    output['state'] = data["state"];
    if (finalResponse && "next_step" in finalResponse) {
      output['nextStep'] = getReadableNextStep(finalResponse["next_step"], finalResponse["params"]);
      output['nextStepReason'] = finalResponse["reason"];
      output['rootCause'] = finalResponse["possible_root_cause_so_far"];
      output['readableRootCause'] = finalResponse["readable_rca"];
      output['githubCommitInfo'] = finalResponse["github_commit_info"];
      //output['stillRunning'] = false;
    }

    output['possibleRootCauseSoFar'] = null;

    var displayAnalysisSteps = [];
    var displayNextStep = "";
    var displayNextStepReason = "";
    for (var stepIndx=0; stepIndx < data["analysis_history"].length; stepIndx++) {
      let analysisStepType = data["analysis_history"][stepIndx].step_type;
      let analysisStepData = data["analysis_history"][stepIndx].step_data;
      //console.log(analysisStepType);
      if (analysisStepType === "next_step") {
        output['possibleRootCauseSoFar'] = analysisStepData["possible_root_cause_so_far"];
        output['readableRootCauseSoFar'] = analysisStepData["readable_rca"];
        if (analysisStepData["next_step"] === "request_logs") {
          displayNextStep = "Process logs from " + analysisStepData["params"]["service"];
        } else if (analysisStepData["next_step"] === "request_metrics") {
          displayNextStep = "Process metrics from " + analysisStepData["params"]["service"] + ' - ' + analysisStepData["params"]["metric_name"];
        } else {
          displayNextStep = analysisStepData["next_step"];
        }
        displayNextStepReason = analysisStepData["reason"];
      } else if (analysisStepType === "observation"){
        if (analysisStepData["observation_type"] === 'log_analysis'){
          displayAnalysisSteps.push(createDisplayStepFromLogAnalysisData(
            analysisStepData, displayNextStep, displayNextStepReason));
        } else if (analysisStepData["observation_type"] === 'metrics_analysis'){
          displayAnalysisSteps.push(createDisplayStepFromMetricsAnalysisData(
            analysisStepData, displayNextStep, displayNextStepReason));
        } else {
          console.log("Unknown observation type: " + analysisStepData["observation_type"]);
        }
      } else if (analysisStepType === "user_input") {
        displayAnalysisSteps.push({
          'step_type': 'user_input',
          'message': analysisStepData['message'],
        });
      }
    }

    if (output['stillRunning'] && data["analysis_history"].length > 0 && data['analysis_history'][data["analysis_history"].length - 1].step_type === 'next_step') {
      displayAnalysisSteps.push({
        'step_type': 'ongoing',
        'currentNextStep': displayNextStep,
        'currentNextStepReason': displayNextStepReason,
      });
    }

    output['analysisSteps'] = displayAnalysisSteps;
    output['feedbackScore'] = data['feedback_score'];
    setAnalysisData(output);
  };

  const onInterrupt = () => {
    return makeAuthenticatedRequest(`/api/dashboard/v2/issues/${props.item.id}/interrupt`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({}),
    }).then((response) => {
      if (response.ok) {
        fetchIssueData(props.item.id);
        return Promise.resolve();
      } else {
        console.log("Failed to interrupt analysis");
        return Promise.reject("Failed to interrupt analysis");
      }
    });
  };

  const onRunAnalysis = () => {
    return makeAuthenticatedRequest(`/api/dashboard/v2/issues/${props.item.id}/analyze`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => {
      if (response.ok) {
        fetchIssueData(props.item.id);
        // Restart the polling mechanism
        setNumPollRequests(0);
        let newPollerId = setInterval(() => {
          fetchIssueData(props.item.id);
        }, 2000);
        setPollerId(newPollerId);
      } else {
        console.error("Failed to run analysis");
        return Promise.reject("Failed to run analysis");
      }
    });
  };

  const onUserPrompt = (message) => {
    // clearPollerIfExists();

    let updatedAnalysisData = {...analysisData};
    updatedAnalysisData.analysisSteps.push({
      'step_type': 'user_input',
      'message': message,
    });
    setAnalysisData(updatedAnalysisData);

    return makeAuthenticatedRequest(`/api/dashboard/v2/issues/${props.item.id}/prompt`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        message: message,
      }),
    }).then((response) => {
      if (response.ok) {
        fetchIssueData(props.item.id);
        // Restart the polling mechanism
        setNumPollRequests(0);
        let newPollerId = setInterval(() => {
          fetchIssueData(props.item.id);
        }, 2000);
        setPollerId(newPollerId);
      } else {
        console.log("Failed to submit message");
        throw new Error("Failed to submit message");
      }
    });
  };

  return (
    <MainContent issue={issueData} analysisData={analysisData} onRunAnalysis={onRunAnalysis} onUserPrompt={onUserPrompt} onInterrupt={onInterrupt} selectIssueFromIdHandler={props.selectIssueFromIdHandler} />
  );
};

export default IssueDetailsPage;
