import { useState, useEffect } from 'react'

import { Accordion } from 'react-bootstrap';
import { IoTrashOutline } from 'react-icons/io5';

import '../components/demo.css';
import LoginModule from '../components/glogin.js';
import {getLoginInfoFromCookie, makeAuthenticatedRequest} from '../routes/utils';

import ServiceItem from '../components/configuration/ServiceItem';
import { datadogSitePopover, datadogApiKeyPopover, datadogAppKeyPopover,
  awsLogsQueryPopover, metricNamePopover, datadogLogsQueryPopover, awsAccessKeyIdPopover,
  gcpLogsQueryPopover } from '../components/configuration/config';
import { IoInformationCircleOutline } from 'react-icons/io5';
import { OverlayTrigger} from 'react-bootstrap';


const Configuration = (props) => {
  const [loginState, setLoginState] = useState(null);

  const [clientSystemId, setClientSystemId] = useState(null);
  const [failureMessage, setFailureMessage] = useState(null);
  const [dataSources, setDataSources] = useState([]);

  const [gcpDatasourceId, setGcpDatasourceId] = useState(null);
  const [awsDatasourceId, setAwsDatasourceId] = useState(null);
  const [jiraDatasourceId, setJiraDatasourceId] = useState(null);

  const clientSystemIdFromParams = props.clientSystemIdFromParams;

  const getDatasourceNameFromId = (datasourceId) => {
    let datasource = dataSources.find((ds) => ds['id'] === datasourceId);
    if (datasource){
      return datasource['data_source_type'];
    }
    return "";
  }

  const setUserDefinedSystemConfig = (userDefinedSystemConfig) => {
    if (userDefinedSystemConfig) {
      if (userDefinedSystemConfig['services']){
        setServices(userDefinedSystemConfig['services']);
      }
      if (userDefinedSystemConfig['other_log_sources']){
        setOtherLogSources(userDefinedSystemConfig['other_log_sources']);
      }
      if (userDefinedSystemConfig['runbook']){
        setRunbook(userDefinedSystemConfig['runbook']);
      } 
    }
  }

  const [getConfigurationTimeoutId, setGetConfigurationTimeoutId] = useState(null);

  useEffect(() => {
    return () => {
      if (getConfigurationTimeoutId) {
        clearTimeout(getConfigurationTimeoutId);
      }
    }
  }, []);

  useEffect(() => {
    setCurrentlyEditingServiceDatasourceId(defaultDatasourceId());
  }, [dataSources]);

  const getConfiguration = async (retryCount=0) => {
    console.log(`getConfiguration called with retryCount: ${retryCount}`);
    if (retryCount >= 5) {
      return;
    }

    if (!clientSystemId) {
      return;
    }

    var url = `/api/configure?client_system_id=${clientSystemId}`;

    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    };

    let response = await makeAuthenticatedRequest(url, options);
    if (response.ok) {
      let response_data = await response.json();
      setDataSources(response_data['data_sources']);
      for (let datasource of response_data['data_sources']) {
        if (datasource['data_source_type'] === 'gcp') {
          setGcpDatasourceId(datasource['id']);
          if (!(datasource['data_kv'] && datasource['data_kv']['active_metrics'] && datasource['data_kv']['active_metrics'].length > 0)){
            setGetConfigurationTimeoutId(setTimeout(() => {
              getConfiguration(retryCount + 1);
            }, 5000));
          }
          if (retryCount === 0){
            testGcpConnection();
          }
        }
        if (datasource['data_source_type'] === 'aws') {
          setAwsDatasourceId(datasource['id']);
          if (!(datasource['data_kv'] && datasource['data_kv']['active_metrics'] && datasource['data_kv']['active_metrics'].length > 0)){
            setGetConfigurationTimeoutId(setTimeout(() => {
              getConfiguration(retryCount + 1);
            }, 5000));
          }
          setAwsAccessKeyId(datasource['configuration_kv']['aws_access_key_id']);
          setAwsSecretAccessKey(datasource['configuration_kv']['aws_secret_access_key']);
          setAwsRegion(datasource['configuration_kv']['aws_region']);
          if (retryCount === 0){
            testAwsConnection();
          }
        }
        if (datasource['data_source_type'] === 'jira') {
          if (retryCount === 0){
            testJiraConnection();
          }
        }
        if (datasource['data_source_type'] === 'datadog') {
          setDatadogDatasourceId(datasource['id']);
          if (!(datasource['data_kv'] && datasource['data_kv']['active_metrics'] && datasource['data_kv']['active_metrics'].length > 0)){
            setGetConfigurationTimeoutId(setTimeout(() => {
              getConfiguration(retryCount + 1);
            }, 5000));
          }
          setDatadogAppKey(datasource['configuration_kv']['app_key']);
          setDatadogApiKey(datasource['configuration_kv']['api_key']);
          setDatadogSite(datasource['configuration_kv']['site']);
          if (retryCount === 0){
            testDatadogConnection();
          }
        }
        if (datasource['data_source_type'] === 'github') {
          if (retryCount === 0){
            testGithubConnection();
          }
          let availableRepos = [];
          if (datasource['data_kv'] && datasource['data_kv']['available_repos']){
            for (let repo of datasource['data_kv']['available_repos']){
              availableRepos.push(repo['full_name']);
            }
          }
          let sel = [];
          for (let repo of availableRepos){
            sel.push({'full_name': repo, 'selected': false, 'branch': 'main'});
          }
          if (datasource['configuration_kv'] && datasource['configuration_kv']['selected_repos']){
            for (let repo of datasource['configuration_kv']['selected_repos']){
              for (let s of sel){
                if (s['full_name'] === repo['full_name']){
                  s['selected'] = true;
                  s['branch'] = repo['branch'];
                }
              }
            }
          }
          console.log("Selected repos: ", sel);
          setSelectedRepos(sel);
        }
      }
      setUserDefinedSystemConfig(response_data['user_provided_system_config']);
    } else {
      setFailureMessage("Failed to fetch data");
    }
  }

  const [selectedRepos, setSelectedRepos] = useState([]);

  const updateGitHubConfig = async () => {
    setIsGitHubLoading(true);
    try {
      let payload = {
        'client_system_id': clientSystemId,
        'data_source': {
          'type': 'github',
          'selected_repos': selectedRepos.filter((repo) => repo.selected).map((repo) => {
            return {
              'full_name': repo.full_name,
              'branch': repo.branch
            };
          }),
        }
      }
      const url = `/api/configure/data_sources`;
      addDataSource(url, payload);
      // Add 2 second wait after addDataSource
      await new Promise(resolve => setTimeout(resolve, 1000));
    } catch (error) {
      console.error('Error connecting GitHub:', error);
    } finally {
      setIsGitHubLoading(false);
    }
  }

  const handleGitHubRepoSelection = (e) => {
    let newSelectedRepos = selectedRepos.map((repo) => {
      if (repo.full_name === e.target.value){
        return {...repo, selected: e.target.checked};
      }
      return repo;
    });
    setSelectedRepos(newSelectedRepos);
  }

  const handleGitHubBranchSelection = (e) => {
    let newSelectedRepos = selectedRepos.map((repo) => {
      if (repo.full_name === e.target.name){
        return {...repo, branch: e.target.value};
      }
      return repo;
    }
    );
    setSelectedRepos(newSelectedRepos);
  }

  const getAwsMetricDefs = () => {
    let awsDatasource = dataSources.find((ds) => ds['data_source_type'] === 'aws');
    if (!awsDatasource){
      return [];
    }
    let data_kv = awsDatasource['data_kv'];
    if (!data_kv){
      return [];
    }
    if (!data_kv['active_metrics']){
      return [];
    }
    return data_kv['active_metrics'];
  }

  const getAwsLogGroupNameOptions = () => {
    let awsDatasource = dataSources.find((ds) => ds['data_source_type'] === 'aws');
    if (!awsDatasource){
      return [];
    }
    let data_kv = awsDatasource['data_kv'];
    if (!data_kv){
      return [];
    }
    if (!data_kv['log_group_names']){
      return [];
    }
    return data_kv['log_group_names'];
  }

  const getAwsMetricNamespaceOptions = () => {
    let allMetrics = getAwsMetricDefs();
    // get list of unique namespaces
    let namespaces = allMetrics.map((metric) => metric['Namespace']);
    let uniqueNamespaces = [...new Set(namespaces)];
    return uniqueNamespaces;
  }

  const getAwsMetricNameOptions = (namespace) => {
    let allMetrics = getAwsMetricDefs();
    let metricsForNamespace = allMetrics.filter((metric) => metric['Namespace'] === namespace);
    let metricNames = [...new Set(metricsForNamespace.map((metric) => metric['MetricName']))];
    return metricNames;
  }

  useEffect(() => {
    getConfiguration()
  }, [clientSystemId]);


  const [jiraDomain, setJiraDomain] = useState("");
  const [jiraEmail, setJiraEmail] = useState("");
  const [jiraToken, setJiraToken] = useState("");
  const [jiraProjectKeys, setJiraProjectKeys] = useState("");
  const [jiraIssueType, setJiraIssueType] = useState("BUG");


  const addDataSource = (url, payload) => {
    return makeAuthenticatedRequest(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: JSON.stringify(payload)
    })
    .then(response => response.json())
    .then(data => {
      //console.log('Success:', data);
      getConfiguration();
      return data;
    })
    .catch((error) => {
      console.error('Error:', error);
      throw error;
    });
  }

  const connectJira = async () => {
    setIsJiraLoading(true);
    try {
      let payload = {
        'client_system_id': clientSystemId,
        'data_source': {
          'type': 'jira',
          'jira_domain': jiraDomain,
          'jira_email': jiraEmail,
          'jira_api_token': jiraToken,
          'jira_project_keys': jiraProjectKeys.split(','),
          'jira_issue_type': jiraIssueType
        }
      }
      const url = `/api/configure/data_sources`;
      addDataSource(url, payload);
      // Add 2 second wait after addDataSource
      await new Promise(resolve => setTimeout(resolve, 1000));
    } catch (error) {
      console.error('Error connecting JIRA:', error);
    } finally {
      setIsJiraLoading(false);
    }
  }

  const [gcpCreds, setGcpCreds] = useState(null);
  const connectGcp = async () => {
    setIsGcpLoading(true);
    try {
      const text = await gcpCreds.text();
      let credentials_json = JSON.parse(text);
      let payload = {
        'client_system_id': clientSystemId,
        'data_source': {
          'type': 'gcp',
          'credentials_json': credentials_json
        }
      }

      const url = `/api/configure/data_sources`;
      await addDataSource(url, payload);
      // Add 2 second wait after addDataSource
      await new Promise(resolve => setTimeout(resolve, 1000));

    } catch (error) {
      console.error('Error connecting GCP:', error);
    } finally {
      setIsGcpLoading(false);
    }
  }

  // Datadog Config
  const [datadogDatasourceId, setDatadogDatasourceId] = useState(null);
  const [datadogApiKey, setDatadogApiKey] = useState("");
  const [datadogAppKey, setDatadogAppKey] = useState("");
  const [datadogSite, setDatadogSite] = useState("");
  const connectDatadog = async () => {
    setIsDatadogLoading(true);
    try {
      let payload = {
        'client_system_id': clientSystemId,
        'data_source': {
          'type': 'datadog',
          'api_key': datadogApiKey,
          'app_key': datadogAppKey,
          'site': datadogSite
        }
      }

      const url = `/api/configure/data_sources`;
      await addDataSource(url, payload);
      // Add 2 second wait after addDataSource
      await new Promise(resolve => setTimeout(resolve, 1000));
    } catch (error) {
      console.error('Error connecting Datadog:', error);
    } finally {
      setIsDatadogLoading(false);
    }
  }

  const [isDatadogServiceSaving, setIsDatadogServiceSaving] = useState(false);

  // Datadog Metrics
  const [currentlyEditingDatadogMetricPrefix, setCurrentlyEditingDatadogMetricPrefix] = useState("");
  const [currentlyEditingDatadogMetricNames, setCurrentlyEditingDatadogMetricNames] = useState([]);

  // const handleDatadogMetricPrefixChange = (e) => {
  //   setCurrentlyEditingDatadogMetricPrefix(e.target.value);
  // }

  const handleDatadogMetricNameChanges = (e) => {
    let selectedOptions = Array.from(e.target.selectedOptions).map((option) => option.value);
    setCurrentlyEditingDatadogMetricNames(selectedOptions);
  }

  const getDatadogMetricDefs = () => {
    let datadogDatasource = dataSources.find((ds) => ds['data_source_type'] === 'datadog');
    if (!datadogDatasource){
      return [];
    }
    let data_kv = datadogDatasource['data_kv'];
    if (!data_kv){
      return [];
    }
    return data_kv['active_metrics'];
  }

  const getDatadogMetricPrefixOptions = () => {
    let allMetrics = getDatadogMetricDefs();
    let prefixes = allMetrics ? allMetrics.map(metric => metric.split('.')[0]) : [];
    let uniquePrefixes = [...new Set(prefixes)];
    return uniquePrefixes;
  }

  const getDatadogMetricNameOptions = (prefix) => {
    let allMetrics = getDatadogMetricDefs();
    let metricsForPrefix = allMetrics.filter((metric) => metric.startsWith(prefix + '.'));
    return metricsForPrefix;
  }

  // const findDatadogMetricByName = (metricName) => {
  //   let allMetrics = getDatadogMetricDefs();
  //   return allMetrics.find((metric) => metric === metricName);
  // }

  const handleSaveDatadogService = async () => {
    setIsDatadogServiceSaving(true);
    try {
      let updatedServices = [...services, {
        'data_source_id': datadogDatasourceId,
        name: currentlyEditingServiceName,
        logs: {
          query: currentlyEditingServiceQuery
        },
        metrics: currentlyEditingDatadogMetricNames.map((metric) => {
          return {
            'description': metric,
            'name': metric
          };
        }),
      }];
      await handleUserDefinedSytemConfigSave(updatedServices, otherLogSources, runbook);
      setCurrentlyEditingServiceDatasourceId(defaultDatasourceId());
      setCurrentlyEditingServiceName("");
      setCurrentlyEditingServiceQuery("");
      setCurrentlyEditingDatadogMetricPrefix("");
      setCurrentlyEditingDatadogMetricNames([]);
    } catch (error) {
      console.error("Error saving Datadog service:", error);
    } finally {
      setIsDatadogServiceSaving(false);
    }
  }

  // AWS Config
  const [awsAccessKeyId, setAwsAccessKeyId] = useState("");
  const [awsSecretAccessKey, setAwsSecretAccessKey] = useState("");
  const [awsRegion, setAwsRegion] = useState("");
  const connectAws = async () => {
    setIsAwsLoading(true);
    // setAwsConnectionStatus(null);
    // setAwsConnectionError(null);

    let payload = {
      'client_system_id': clientSystemId,
      'data_source': {
        'type': 'aws',
        'aws_access_key_id': awsAccessKeyId,
        'aws_secret_access_key': awsSecretAccessKey,
        'aws_region': awsRegion
      }
    }

    const url = `/api/configure/data_sources`;
    try {
      await addDataSource(url, payload);

      // Add 2 second wait after addDataSource
      await new Promise(resolve => setTimeout(resolve, 1000));

      // setAwsConnectionStatus('success');
    } catch (error) {
      // setAwsConnectionStatus('error');
      // setAwsConnectionError('An error occurred while connecting to AWS. Please check your credentials.');
      console.error('Error connecting to AWS:', error);
    } finally {
      setIsAwsLoading(false);
    }
  }

  const [currentlyEditingAwsMetricNamespace, setCurrentlyEditingAwsMetricNamespace] = useState("");
  const [currentlyEditingAwsMetricNames, setCurrentlyEditingAwsMetricNames] = useState([]);
  const [currentlyEditingAwsLogGroupName, setCurrentlyEditingAwsLogGroupName] = useState("");

  const handleAwsMetricNameChanges = (e) => {
    let selectedOptions = Array.from(e.target.selectedOptions).map((option) => option.value);
    setCurrentlyEditingAwsMetricNames(selectedOptions);
  };


  const defaultDatasourceId = () => {
    return dataSources.length === 1 ? dataSources[0]['id'] : "";
  }

  const [isAwsServiceSaving, setIsAwsServiceSaving] = useState(false);
  const handleSaveAwsService = async () => {
    setIsAwsServiceSaving(true);

    try {
      let logQuery = (currentlyEditingServiceQuery.length > 0) ? currentlyEditingServiceQuery : '*';
      let updatedServices = [...services, {
        'data_source_id': awsDatasourceId,
        name: currentlyEditingServiceName,
        logs: {
          query: logQuery,
          'log_group_name': currentlyEditingAwsLogGroupName,
        },
        metrics: currentlyEditingAwsMetricNames.map((metric) => {
          return {
            'namespace': currentlyEditingAwsMetricNamespace,
            'name': metric
          };
        }),
      }];

      await handleUserDefinedSytemConfigSave(updatedServices, otherLogSources, runbook);
      setCurrentlyEditingServiceDatasourceId(defaultDatasourceId());
      setCurrentlyEditingServiceName("");
      setCurrentlyEditingServiceQuery("");
      setCurrentlyEditingAwsMetricNamespace("");
      setCurrentlyEditingAwsMetricNames([]);
    } catch (error) {
      console.error("Error saving AWS service:", error);
    } finally {
      setIsAwsServiceSaving(false);
    }
  }

  const [currentlyEditingServiceDatasourceId, setCurrentlyEditingServiceDatasourceId] = useState(defaultDatasourceId());
  const [currentlyEditingServiceName, setCurrentlyEditingServiceName] = useState("");
  const [currentlyEditingServiceQuery, setCurrentlyEditingServiceQuery] = useState("");

  // GCP Metrics
  const [currentlyEditingGcpMetricProduct, setCurrentlyEditingGcpMetricProduct] = useState("");
  const [currentlyEditingGcpMetricNames, setCurrentlyEditingGcpMetricNames] = useState([]);

  const handleGcpMetricNameChanges = (e) => {
    let selectedOptions = Array.from(e.target.selectedOptions).map((option) => option.value);
    setCurrentlyEditingGcpMetricNames(selectedOptions);
  }

  const getGcpMetricDefs = () => {
    let gcpDatasource = dataSources.find((ds) => ds['data_source_type'] === 'gcp');
    if (!gcpDatasource){
      return [];
    }
    let data_kv = gcpDatasource['data_kv'];
    if (!data_kv){
      return [];
    }
    return data_kv['active_metrics'];
  }

  const getGcpMetricProductOptions = () => {
    let allMetrics = getGcpMetricDefs();
    // get list of unique products
    let products = allMetrics.map((metric) => metric['name'].split('.')[0]);
    let uniqueProducts = [...new Set(products)];
    return uniqueProducts;
  }

  const getGcpMetricNameOptions = (product) => {
    let allMetrics = getGcpMetricDefs();
    let metricsForNamespace = allMetrics.filter((metric) => metric['name'].split('.')[0] === product);
    let metricNames = [...new Set(metricsForNamespace.map((metric) => metric['name']))];
    return metricNames;
  }

  const findGcpMetricByName = (metricName) => {
    let allMetrics = getGcpMetricDefs();
    return allMetrics.find((metric) => metric['name'] === metricName);
  }

  const [services, setServices] = useState([]);


  // const handleSaveService = () => {
  //   if (currentlyEditingServiceName) {
  //     let updatedServices = [...services, {
  //       'data_source_id': gcpDatasourceId,
  //       name: currentlyEditingServiceName,
  //       logs: {
  //         query: currentlyEditingServiceQuery
  //       },
  //       metrics: {
  //         dashboard: currentlyEditingServiceMetricsDashboard
  //       }
  //     }];
  //     handleUserDefinedSytemConfigSave(updatedServices, otherLogSources, runbook);
  //     setCurrentlyEditingServiceName("");
  //     setCurrentlyEditingServiceQuery("");
  //     setCurrentlyEditingServiceMetricsDashboard("");
  //   }
  // }

  const handleRemoveService = (index) => {
    let updatedServices = services.filter((_, i) => i !== index);
    handleUserDefinedSytemConfigSave(updatedServices, otherLogSources, runbook);
  }

  
  const [isSavingGcpService, setIsSavingGcpService] = useState(false);

  const handleSaveGcpService = async () => {
    setIsSavingGcpService(true);
    try {
      let updatedServices = [...services, {
        'data_source_id': gcpDatasourceId,
        name: currentlyEditingServiceName,
        logs: {
          query: currentlyEditingServiceQuery
        },
        metrics: currentlyEditingGcpMetricNames.map((metric) => {
          return findGcpMetricByName(metric);
        }),
      }];
      await handleUserDefinedSytemConfigSave(updatedServices, otherLogSources, runbook);
      setCurrentlyEditingServiceName("");
      setCurrentlyEditingServiceQuery("");
      setCurrentlyEditingGcpMetricProduct("");
      setCurrentlyEditingGcpMetricNames([]);
    } catch (error) {
      console.error("Error saving GCP service:", error);
      // Optionally, set an error state here to display to the user
    } finally {
      setIsSavingGcpService(false);
    }
  }

  const [currentlyEditingOtherLogSourceDatasourceId, setCurrentlyEditingOtherLogSourceDatasourceId] = useState("");
  const [currentlyEditingOtherLogSourceDescription, setCurrentlyEditingOtherLogSourceDescription] = useState("");
  const [currentlyEditingOtherLogSourceQuery, setCurrentlyEditingOtherLogSourceQuery] = useState("");

  const [otherLogSources, setOtherLogSources] = useState([]);

  // const handleSaveOtherLogSource = () => {
  //   let updatedOtherLogSources = [...otherLogSources, {
  //     'data_source_id': currentlyEditingOtherLogSourceDatasourceId,
  //     name: currentlyEditingOtherLogSourceDescription,
  //     query: currentlyEditingOtherLogSourceQuery
  //   }];
  //   setCurrentlyEditingOtherLogSourceDescription("");
  //   setCurrentlyEditingOtherLogSourceQuery("");
  //   handleUserDefinedSytemConfigSave(services, updatedOtherLogSources, runbook);
  // }

  const serviceNamePlaceholderText = 'Enter service name\n(e.g. Authentication Service, Payment Processing)';
  const gcpServicePlaceholderQuery = 'Enter GCP log query\n(e.g. resource.type="cloud_run_revision" AND resource.labels.service_name="authentication-service")';
  const awsServicePlaceholderQuery = 'Enter AWS CloudWatch log query\n(e.g. *)';
  const datadogServicePlaceholderQuery = 'Enter Datadog log query\n(e.g. service:authentication-service AND environment:production)';

  const runbookPlaceholderText = `- For login related issues, we look at logs from the user service.\n- For database issues, escalate to platforms team.
  `;

  const [runbook, setRunbook] = useState("");
  const [isRunbookSaving, setIsRunbookSaving] = useState(false);
  const handleRunbookSave = async () => {
    setIsRunbookSaving(true);
    await handleUserDefinedSytemConfigSave(services, otherLogSources, runbook);
    setIsRunbookSaving(false);
  }

  const handleUserDefinedSytemConfigSave = (updatedServices, updatedOtherLogSources, updatedRunbook) => {
    return new Promise((resolve, reject) => {
      let payload = {
        'client_system_id': clientSystemId,
        'services': updatedServices,
        'other_log_sources': updatedOtherLogSources,
        'runbook': updatedRunbook
      }
      makeAuthenticatedRequest(`/api/configure`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify(payload)
      }).then(response => response.json())
        .then(data => {
          //console.log('Success:', data);
          getConfiguration();
          resolve(data);
        })
        .catch((error) => {
          console.error('Error:', error);
          reject(error);
        });
    });
  }

  const saveConfiguration = () => {
    window.location.href = "/monitor";
  }

  const getNextButtonText = () => {
    return "Go to dashboard!"
  }

  const [gcpConnectionStatus, setGcpConnectionStatus] = useState(null);
  const [awsConnectionStatus, setAwsConnectionStatus] = useState(null);
  const [datadogConnectionStatus, setDatadogConnectionStatus] = useState(null);
  const [jiraConnectionStatus, setJiraConnectionStatus] = useState(null);
  const [githubConnectionStatus, setGithubConnectionStatus] = useState(null);

  const [gcpConnectionError, setGcpConnectionError] = useState(null);
  const [awsConnectionError, setAwsConnectionError] = useState(null);
  const [jiraConnectionError, setJiraConnectionError] = useState(null);
  const [datadogConnectionError, setDatadogConnectionError] = useState(null);
  const [githubConnectionError, setGithubConnectionError] = useState(null);

  const [isGcpLoading, setIsGcpLoading] = useState(false);
  const [isAwsLoading, setIsAwsLoading] = useState(false);
  const [isJiraLoading, setIsJiraLoading] = useState(false);
  const [isDatadogLoading, setIsDatadogLoading] = useState(false);
  const [isGitHubLoading, setIsGitHubLoading] = useState(false);

  // Modify testGcpConnection to accept clientSystemId as a parameter
  const testGcpConnection = async () => {
    setGcpConnectionStatus(null);
    setGcpConnectionError(null);

    const url = `/api/test_gcp_connection`;
    if (!clientSystemId) {
      console.log("No client system id found");
      setGcpConnectionStatus(null);
      setGcpConnectionError(null);
      return;
    }
    //console.log("Testing GCP connection for client system id:", clientSystemId);
    const payload = {
      client_system_id: clientSystemId
    };

    try {
      const response = await makeAuthenticatedRequest(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify(payload)
      });

      const data = await response.json();

      if (response.ok) {
        setGcpConnectionStatus('success');
        //console.log('Connection test successful:', data);
      } else {
        if (data.error && data.error.startWith('GCP data source not found')){
          setGcpConnectionStatus(null);
          setGcpConnectionError(null);
        } else {
          setGcpConnectionStatus('error');
          setGcpConnectionError(data.error || 'An error occurred while testing the connection. Please check your credentials.');
          console.error('Connection test failed:', data);
        }
      }
    } catch (error) {
      setGcpConnectionStatus('error');
      setGcpConnectionError('An error occurred while testing the connection');
      console.error('Error testing connection:', error);
    }
  };

  const testGithubConnection = async () => {
    setGithubConnectionStatus(null);
    setGithubConnectionError(null);
  
    const url = `/api/data_sources/github/test`;
    if (!clientSystemId) {
      console.log("No client system id found");
      setGithubConnectionStatus(null);
      setGithubConnectionError(null);
      return;
    }
  
    const payload = {
      client_system_id: clientSystemId
    };
  
    try {
      const response = await makeAuthenticatedRequest(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify(payload)
      });
  
      const data = await response.json();
  
      if (response.ok) {
        setGithubConnectionStatus('success');
      } else {
        if (data.error && data.error.startsWith('JIRA data source not found')) {
          setGithubConnectionStatus(null);
          setGithubConnectionError(null);
        } else {
          setGithubConnectionStatus('error');
          setGithubConnectionError(data.error || 'An error occurred while testing the connection. Please check your credentials.');
          console.error('Connection test failed:', data);
        }
      }
    } catch (error) {
      setGithubConnectionStatus('error');
      setGithubConnectionError('An error occurred while testing the connection');
      console.error('Error testing connection:', error);
    }
  };

  const testDatadogConnection = async () => {
    setDatadogConnectionStatus(null);
    setDatadogConnectionError(null);
  
    const url = `/api/test_datadog_connection`;
    if (!clientSystemId) {
      console.log("No client system id found");
      setDatadogConnectionStatus(null);
      setDatadogConnectionError(null);
      return;
    }
  
    const payload = {
      client_system_id: clientSystemId
    };
  
    try {
      const response = await makeAuthenticatedRequest(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify(payload)
      });
  
      const data = await response.json();
  
      if (response.ok) {
        setDatadogConnectionStatus('success');
      } else {
        if (data.error && data.error.startsWith('Datadog data source not found')) {
          setDatadogConnectionStatus(null);
          setDatadogConnectionError(null);
        } else {
          setDatadogConnectionStatus('error');
          setDatadogConnectionError(data.error || 'An error occurred while testing the connection. Please check your credentials.');
          console.error('Connection test failed:', data);
        }
      }
    } catch (error) {
      setDatadogConnectionStatus('error');
      setDatadogConnectionError('An error occurred while testing the connection');
      console.error('Error testing connection:', error);
    }
  };

  const testJiraConnection = async () => {
    setJiraConnectionStatus(null);
    setJiraConnectionError(null);
  
    const url = `/api/test_jira_connection`;
    if (!clientSystemId) {
      console.log("No client system id found");
      setJiraConnectionStatus(null);
      setJiraConnectionError(null);
      return;
    }
  
    const payload = {
      client_system_id: clientSystemId
    };
  
    try {
      const response = await makeAuthenticatedRequest(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify(payload)
      });
  
      const data = await response.json();
  
      if (response.ok) {
        setJiraConnectionStatus('success');
      } else {
        if (data.error && data.error.startsWith('JIRA data source not found')) {
          setJiraConnectionStatus(null);
          setJiraConnectionError(null);
        } else {
          setJiraConnectionStatus('error');
          setJiraConnectionError(data.error || 'An error occurred while testing the connection. Please check your credentials.');
          console.error('Connection test failed:', data);
        }
      }
    } catch (error) {
      setJiraConnectionStatus('error');
      setJiraConnectionError('An error occurred while testing the connection');
      console.error('Error testing connection:', error);
    }
  };
  const testAwsConnection = async () => {
    setAwsConnectionStatus(null);
    setAwsConnectionError(null);
  
    const url = `/api/test_aws_connection`;
    if (!clientSystemId) {
      console.log("No client system id found");
      setAwsConnectionStatus(null);
      setAwsConnectionError(null);
      return;
    }
  
    const payload = {
      client_system_id: clientSystemId
    };
  
    try {
      const response = await makeAuthenticatedRequest(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify(payload)
      });
  
      const data = await response.json();
  
      if (response.ok) {
        setAwsConnectionStatus('success');
      } else {
        if (data.error && data.error.startsWith('AWS data source not found')) {
          setAwsConnectionStatus(null);
          setAwsConnectionError(null);
        } else {
          setAwsConnectionStatus('error');
          setAwsConnectionError(data.error || 'An error occurred while testing the connection. Please check your credentials.');
          console.error('Connection test failed:', data);
        }
      }
    } catch (error) {
      setAwsConnectionStatus('error');
      setAwsConnectionError('An error occurred while testing the connection');
      console.error('Error testing connection:', error);
    }
  };

  // Update connectAndTestGcp to use the current clientSystemId state
  // const connectAndTestGcp = async () => {
  //   setGcpConnectionStatus(null);
  //   setGcpConnectionError(null);
  //   setIsGcpLoading(true);

  //   try {
  //     await connectGcp();
  //     await new Promise(resolve => setTimeout(resolve, 2000)); // 2 second delay
  //     await testGcpConnection();
  //   } catch (error) {
  //     setGcpConnectionStatus('error');
  //     setGcpConnectionError('Failed to connect to GCP');
  //     console.error('Error connecting to GCP:', error);
  //   } finally {
  //     setIsGcpLoading(false);
  //   }
  // };

  const getGcpProjectId = () => {
    const gcpDataSource = dataSources.find(ds => ds.data_source_type === 'gcp');
    if (gcpDataSource && gcpDataSource.configuration_kv && gcpDataSource.configuration_kv.credentials_json) {
      return gcpDataSource.configuration_kv.credentials_json.project_id;
    }
    return null;
  };

  const buttonStyle = {
    backgroundColor: '#5935c9',
    borderColor: '#5935c9',
    color: 'white',
    transition: 'background-color 0.3s, color 0.3s'
  };

  const selectOnChange = (e) => {
    setCurrentlyEditingServiceDatasourceId(e.target.value);
  }


  const loginCallback = (data) => {
    const clId = clientSystemIdFromParams || data.workspaces[0].client_system_id;
    setClientSystemId(clId);
    setLoginState('logged_in');
  }

  const loginFailureCallback = () => {
    setLoginState('not_logged_in');
    window.location.href = '/';
  }

  useEffect(() => {
    if (loginState === null) {
      getLoginInfoFromCookie(loginCallback, loginFailureCallback);
    }
  }, [])

  if (!loginState){
    return "";
  }

  if (!clientSystemId && loginState === 'not_logged_in') {
    return (<LoginModule onSuccessCallback={loginCallback} />);
  }

  return (
    <div className="container">
      <div className="row my-3">
        <div className="col-md-12">
          <h4>👋. Welcome, let's get you started!</h4>
        </div>
      </div>
      <div className="row my-3">
        <h5 className="my-4">Step 1. Let's start by connecting your observability tools</h5>
        <Accordion alwaysOpen>
          {/* GCP */}
          <Accordion.Item key={0} eventKey={"0"}>
            <Accordion.Header>
              <img src="/img/gcp_logo.png" alt="GCP Logo" style={{width: '32px', height: '32px', marginRight: '8px'}} />
              Connect Google Cloud Monitoring
              {gcpConnectionStatus && (
                <span className={`badge ms-2 ${gcpConnectionStatus === 'success' ? 'bg-success' : 'bg-danger'}`}>
                  {gcpConnectionStatus === 'success' ? 'Connected' : 'Connection Failed'}
                </span>
              )}
            </Accordion.Header>
            <Accordion.Body>
              {gcpConnectionStatus === 'success' && getGcpProjectId() && (
                <div className="alert alert-success mb-3">
                  Connected to GCP Project: <strong>{getGcpProjectId()}</strong>
                </div>
              )}
              <div className="mb-3">
                <label htmlFor="formFile" className="form-label">Credentials JSON File</label>
                <input className="form-control" type="file" id="formFile" onChange={(e) => setGcpCreds(e.target.files[0])} />
              </div>
              <button 
                className="btn btn-primary custom-btn" 
                disabled={!gcpCreds || isGcpLoading} 
                onClick={connectGcp}
                style={buttonStyle}
              >
                {isGcpLoading ? (
                  <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                ) : null}
                Connect GCP
              </button>
              {gcpConnectionError && (
                <div className="alert alert-danger mt-2" role="alert">
                  {gcpConnectionError}
                </div>
              )}
            </Accordion.Body>
          </Accordion.Item>
          {/* AWS */}
          <Accordion.Item key={1} eventKey={"1"}>
            <Accordion.Header>
              <img src="/img/aws_logo.png" alt="AWS Logo" style={{width: '32px', height: '20px', marginRight: '8px'}} />
              Connect AWS CloudWatch
              {awsConnectionStatus && (
                <span className={`badge ms-2 ${awsConnectionStatus === 'success' ? 'bg-success' : 'bg-danger'}`}>
                  {awsConnectionStatus === 'success' ? 'Connected' : 'Connection Failed'}
                </span>
              )}
            </Accordion.Header>
            <Accordion.Body>
              <div className="mb-3">
                <label htmlFor="awsAccessKeyId">AWS Access Key ID</label>
                <OverlayTrigger trigger="click" placement="right" overlay={awsAccessKeyIdPopover}>
                  <span>
                    <IoInformationCircleOutline className="ms-2" style={{ cursor: 'pointer', color: '#5935c9' }} />
                  </span>
                </OverlayTrigger>
                <input type="text" className="form-control my-3" value={awsAccessKeyId} id="awsAccessKeyId" placeholder="" onChange={(e) => setAwsAccessKeyId(e.target.value)} />
                <label htmlFor="awsSecretAccessKey">AWS Secret Access Key</label>
                <OverlayTrigger trigger="click" placement="right" overlay={awsAccessKeyIdPopover}>
                  <span>
                    <IoInformationCircleOutline className="ms-2" style={{ cursor: 'pointer', color: '#5935c9' }} />
                  </span>
                </OverlayTrigger>
                <input type="password" className="form-control my-3" value={awsSecretAccessKey} id="awsSecretAccessKey" placeholder="" onChange={(e) => setAwsSecretAccessKey(e.target.value)} />
                <label htmlFor="awsRegion">AWS Region</label>
                <input type="text" className="form-control my-3" value={awsRegion} id="awsRegion" placeholder="" onChange={(e) => setAwsRegion(e.target.value)} />
              </div>
              <button 
                className="btn btn-primary custom-btn" 
                style={buttonStyle} 
                disabled={awsAccessKeyId.length === 0 || awsSecretAccessKey.length === 0 || awsRegion.length === 0 || isAwsLoading} 
                onClick={connectAws}
              >
                {isAwsLoading ? (
                  <>
                    <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                  </>
                ) : null}
                Connect AWS
              </button>
            </Accordion.Body>
          </Accordion.Item>
          {/* Datadog */}
          <Accordion.Item key={2} eventKey={"2"}>
            <Accordion.Header>
              <img src="/img/dd_icon_rgb.png" alt="Datadog Logo" style={{width: '32px', height: '32px', marginRight: '8px'}} />
              Connect Datadog
              {datadogConnectionStatus && (
                <span className={`badge ms-2 ${datadogConnectionStatus === 'success' ? 'bg-success' : 'bg-danger'}`}>
                  {datadogConnectionStatus === 'success' ? 'Connected' : 'Connection Failed'}
                </span>
              )}
            </Accordion.Header>
            <Accordion.Body>
            <div className="mb-3">
            <label htmlFor="datadogSite">
              Datadog Site
              <OverlayTrigger trigger="click" placement="right" overlay={datadogSitePopover}>
                <span>
                  <IoInformationCircleOutline className="ms-2" style={{ cursor: 'pointer', color: '#5935c9' }} />
                </span>
              </OverlayTrigger>
            </label>
              <input type="text" className="form-control my-3" value={datadogSite} id="datadogSite" placeholder="" onChange={(e) => setDatadogSite(e.target.value)} />
              <label htmlFor="datadogApiKey">
                Datadog API Key
                <OverlayTrigger trigger="click" placement="right" overlay={datadogApiKeyPopover} >
                  <span>
                    <IoInformationCircleOutline className="ms-2" style={{ cursor: 'pointer', color: '#5935c9' }} />
                  </span>
                </OverlayTrigger>
              </label>
              <input type="password" className="form-control my-3" value={datadogApiKey} id="datadogApiKey" placeholder="" onChange={(e) => setDatadogApiKey(e.target.value)} />
              <label htmlFor="datadogAppKey">
              Datadog APP Key
              <OverlayTrigger trigger="click" placement="right" overlay={datadogAppKeyPopover}>
                <span>
                  <IoInformationCircleOutline className="ms-2" style={{ cursor: 'pointer', color: '#5935c9' }} />
                </span>
              </OverlayTrigger>
            </label>
              <input type="password" className="form-control my-3" value={datadogAppKey} id="datadogAppKey" placeholder="" onChange={(e) => setDatadogAppKey(e.target.value)} />
            </div>
              <button 
                className="btn btn-primary custom-btn" 
                style={buttonStyle} 
                disabled={datadogApiKey.length === 0 || datadogAppKey.length === 0 || datadogSite.length === 0 || isDatadogLoading} 
                onClick={connectDatadog}
              >
                {isDatadogLoading ? (
                  <>
                    <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                  </>
                ) : null}
                Connect Datadog
              </button>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>


      <div className="row my-3">
        <div className="col-md-12">
          <h5 className="my-4">Step 2. Tell us a little bit about how you debug issues in this project.</h5>
          <p>Imagine you are onboarding a new engineer to this project. No need to be very detailed - we are looking for a few hints to get started.</p>
        </div>
      </div>
      <Accordion alwaysOpen>
        <Accordion.Item key={0} eventKey={"0"}>
          <Accordion.Header>What services do you investigate?</Accordion.Header>
          <Accordion.Body>
            <div className="row my-3">
              <div className="col-md-3"><p><strong>Service name</strong></p></div>
              <div className="col-md-4"><p><strong>Logs Query</strong></p></div>
              <div className="col-md-4"><p><strong>Metrics</strong></p></div>
              <div className="col-md-1"></div>
            </div>

            {services.map((service, index) => (
              <ServiceItem
                key={index}
                service={service}
                index={index}
                getDatasourceNameFromId={getDatasourceNameFromId}
                handleRemoveService={handleRemoveService}
              />
            ))}

            <div className="form-group">
              {dataSources.length > 0 ? (
                <div className="row my-3">
                  <div className="col-md-3">
                    <select className="form-select my-3" value={currentlyEditingServiceDatasourceId} onChange={selectOnChange}>
                      <option value="">Select datasource</option>
                      {gcpDatasourceId && <option key="gcp" value={gcpDatasourceId}>GCP</option>}
                      {awsDatasourceId && <option key="aws" value={awsDatasourceId}>AWS</option>}
                      {datadogDatasourceId && <option key="datadog" value={datadogDatasourceId}>Datadog</option>}
                    </select>
                  </div>
                </div>
              ) : (
                <p className="text-warning fw-bold border border-warning rounded p-2 d-inline-block">
                  <small>⚠️ Please connect to a data source first</small>
                </p>
              )}
              {currentlyEditingServiceDatasourceId == gcpDatasourceId &&
              <div>
                <div className="row my-3">
                  <div className="col-md-3">
                    <label className='mx-2 label-text' htmlFor="gcpServiceName">Service name</label>
                    <textarea className="form-control my-1" rows="10" placeholder={serviceNamePlaceholderText} value={currentlyEditingServiceName} onChange={(e) => setCurrentlyEditingServiceName(e.target.value)}></textarea>
                  </div>
                  <div className="col-md-4">
                    <label className='mx-2 label-text' htmlFor="gcpServiceLogsQuery">
                      Logs query
                      <OverlayTrigger trigger="click" placement="right" overlay={gcpLogsQueryPopover}>
                        <span>
                          <IoInformationCircleOutline className="ms-2" style={{ cursor: 'pointer', color: '#5935c9' }} />
                        </span>
                      </OverlayTrigger>
                    </label>
                    <textarea className="form-control my-1" id="runbookQuery" rows="10" placeholder={gcpServicePlaceholderQuery} value={currentlyEditingServiceQuery} onChange={(e) => setCurrentlyEditingServiceQuery(e.target.value)}></textarea>
                  </div>
                  <div className="col-md-4">
                    <div clas="row">
                      <div className="col-md-12">
                        <label className='mx-2 label-text' htmlFor="gcpMetricProductName">
                          GCP Product
                        </label>
                        <select className="form-select my-1" id="gcpMetricProductName" value={currentlyEditingGcpMetricProduct} onChange={(e) => setCurrentlyEditingGcpMetricProduct(e.target.value)}>
                          <option value="">Select GCP Product</option>
                          {getGcpMetricProductOptions().map((product) => {
                            return <option key={product} value={product}>{product}</option>;
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <label className='mx-2 label-text' htmlFor="gcpMetricName">
                          Metric
                          <OverlayTrigger trigger="click" placement="right" overlay={metricNamePopover}>
                            <span>
                              <IoInformationCircleOutline className="ms-2" style={{ cursor: 'pointer', color: '#5935c9' }} />
                            </span>
                          </OverlayTrigger>
                        </label>
                        <select multiple={true} className="form-select my-1" id="gcpMetricName" value={currentlyEditingGcpMetricNames} onChange={handleGcpMetricNameChanges}>
                          <option value="">Select metric</option>
                          {getGcpMetricNameOptions(currentlyEditingGcpMetricProduct).map((metric) => {
                            return <option key={metric} value={metric}>{metric}</option>;
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <button 
                disabled={currentlyEditingServiceName.length === 0 ||currentlyEditingServiceQuery.length === 0 || isSavingGcpService} 
                onClick={handleSaveGcpService} 
                className="btn btn-primary custom-btn"
                style={buttonStyle}
              >
                {isSavingGcpService ? (
                  <>
                    <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                    Saving...
                  </>
                ) : (
                  'Save'
                )}
              </button>
              </div>}

              {currentlyEditingServiceDatasourceId == awsDatasourceId &&
              <div>
                <div className="row my-3">
                  <div className="col-md-3">
                    <label className='mx-2 label-text' htmlFor="awsServiceName">Service name</label>
                    <textarea id="awsServiceName" className="form-control my-1" rows="10" placeholder={serviceNamePlaceholderText} value={currentlyEditingServiceName} onChange={(e) => setCurrentlyEditingServiceName(e.target.value)}></textarea>
                  </div>
                  <div className="col-md-4">
                    <div className="row">
                      <div className="col-md-12">
                        <label className='mx-2 label-text' htmlFor="awsLogGroupName">Log group</label>
                        <select className="form-select my-1" id="awsLogGroupName" value={currentlyEditingAwsLogGroupName} onChange={(e) => setCurrentlyEditingAwsLogGroupName(e.target.value)}>
                          <option value="">Select log group</option>
                          {getAwsLogGroupNameOptions().map((logGroupName) => {
                            return <option key={logGroupName} value={logGroupName}>{logGroupName}</option>;
                            })}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <label className='mx-2 label-text' htmlFor="awsServiceLogsQuery">
                        Logs query
                        <OverlayTrigger trigger="click" placement="right" overlay={awsLogsQueryPopover}>
                          <span>
                            <IoInformationCircleOutline className="ms-2" style={{ cursor: 'pointer', color: '#5935c9' }} />
                          </span>
                        </OverlayTrigger>
                      </label>
                      <textarea 
                        id="awsServiceLogsQuery" 
                        className="form-control my-1" 
                        rows="10" 
                        placeholder={awsServicePlaceholderQuery} 
                        value={currentlyEditingServiceQuery} 
                        onChange={(e) => setCurrentlyEditingServiceQuery(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div clas="row">
                      <div className="col-md-12">
                        <label className='mx-2 label-text' htmlFor="awsMetricNamespace">Namespace</label>
                        <select className="form-select my-1" id="awsMetricNamespace" value={currentlyEditingAwsMetricNamespace} onChange={(e) => setCurrentlyEditingAwsMetricNamespace(e.target.value)}>
                          <option value="">Select namespace</option>
                          {getAwsMetricNamespaceOptions().map((namespace) => {
                            return <option key={namespace} value={namespace}>{namespace}</option>;
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <label className='mx-2 label-text' htmlFor="awsMetricName">
                          Metric
                          <OverlayTrigger trigger="click" placement="right" overlay={metricNamePopover}>
                            <span>
                              <IoInformationCircleOutline className="ms-2" style={{ cursor: 'pointer', color: '#5935c9' }} />
                            </span>
                          </OverlayTrigger>
                        </label>
                        <select multiple={true} className="form-select my-1" id="awsMetricName" value={currentlyEditingAwsMetricNames} onChange={handleAwsMetricNameChanges}>
                          <option value="">Select metric</option>
                          {getAwsMetricNameOptions(currentlyEditingAwsMetricNamespace).map((metric) => {
                            return <option key={metric} value={metric}>{metric}</option>;
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <button 
                disabled={currentlyEditingServiceName.length === 0 ||currentlyEditingServiceQuery.length === 0 || currentlyEditingAwsLogGroupName.length === 0 || isAwsServiceSaving} 
                onClick={handleSaveAwsService} 
                className="btn btn-primary custom-btn"
                style={buttonStyle}
                >
                {isAwsServiceSaving ? (
                  <>
                    <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                    Saving...
                  </>
                ) : (
                  'Save'
                )}
                </button>
              </div>}

              {currentlyEditingServiceDatasourceId == datadogDatasourceId &&
              <div>
                <div className="row my-3">
                  <div className="col-md-3">
                    <label className='mx-2 label-text' htmlFor="datadogServiceName">Service name</label>
                    <textarea className="form-control my-1" rows="10" placeholder={serviceNamePlaceholderText} value={currentlyEditingServiceName} onChange={(e) => setCurrentlyEditingServiceName(e.target.value)}></textarea>
                  </div>
                  <div className="col-md-4">
                    <label className='mx-2 label-text' htmlFor="datadogServiceLogsQuery">
                      Logs query
                      <OverlayTrigger trigger="click" placement="right" overlay={datadogLogsQueryPopover}>
                        <span>
                        <IoInformationCircleOutline className="ms-2" style={{ cursor: 'pointer', color: '#5935c9' }} />
                      </span>
                    </OverlayTrigger>
                    </label>
                    <textarea className="form-control my-1" id="datadogServiceLogsQuery" rows="10" placeholder={datadogServicePlaceholderQuery} value={currentlyEditingServiceQuery} onChange={(e) => setCurrentlyEditingServiceQuery(e.target.value)}></textarea>
                  </div>
                  <div className="col-md-4">
                    <div clas="row">
                      <div className="col-md-12">
                        <label className='mx-2 label-text' htmlFor="datadogMetricProductPrefix">Metric prefix</label>
                        <select className="form-select my-1" id="datadogMetricProductPrefix" value={currentlyEditingDatadogMetricPrefix} onChange={(e) => setCurrentlyEditingDatadogMetricPrefix(e.target.value)}>
                          <option value="">Select Metric Prefix</option>
                          {getDatadogMetricPrefixOptions().map((prefix) => {
                            return <option key={prefix} value={prefix}>{prefix}</option>;
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <label className='mx-2 label-text' htmlFor="datadogMetricName">
                          Metric
                          <OverlayTrigger trigger="click" placement="right" overlay={metricNamePopover}>
                            <span>
                              <IoInformationCircleOutline className="ms-2" style={{ cursor: 'pointer', color: '#5935c9' }} />
                            </span>
                          </OverlayTrigger>
                        </label>
                        <select multiple={true} className="form-select my-1" id="datadogMetricName" value={currentlyEditingDatadogMetricNames} onChange={handleDatadogMetricNameChanges}>
                          <option value="">Select metric</option>
                          {getDatadogMetricNameOptions(currentlyEditingDatadogMetricPrefix).map((metric) => {
                            return <option key={metric} value={metric}>{metric}</option>;
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <button 
                disabled={currentlyEditingServiceName.length === 0 ||currentlyEditingServiceQuery.length === 0 || isDatadogServiceSaving} 
                onClick={handleSaveDatadogService} 
                className="btn btn-primary custom-btn"
                style={buttonStyle}
              >
                {isDatadogServiceSaving ? (
                  <>
                    <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                    Saving...
                  </>
                ) : (
                  'Save'
                )}
              </button>
              </div>}

            </div>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item key={2} eventKey={"2"}>
          <Accordion.Header>Any other instructions/playbook?</Accordion.Header>
          <Accordion.Body>
            <div className="form-group">
              <div className="row my-3">
                <div className="col-md-12">
                  <textarea className="form-control my-3" rows="10" placeholder={runbookPlaceholderText} value={runbook} onChange={(e) => setRunbook(e.target.value)}></textarea>
                </div>
              </div>
              <button 
                disabled={runbook.length === 0} 
                onClick={handleRunbookSave} 
                className="btn btn-primary custom-btn"
                style={buttonStyle}
              >
                {isRunbookSaving ? (
                  <>
                    <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                    Saving...
                  </>
                ) : (
                  'Save runbook'
                )}
              </button>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <div className="row my-3">
        <div className="col-md-12">
          {/* <h5 className="my-4">[Optional] Step 3. Connect your GitHub</h5> */}
          <h5 className="my-4">
            Step 3. Connect your GitHub
            <span className="badge bg-secondary ms-2" style={{ fontSize: '0.5em', fontWeight: 'normal' }}>Optional</span>
          </h5>
          <Accordion alwaysOpen>
            <Accordion.Item key={0} eventKey={"0"}>
              <Accordion.Header>
                Connect GitHub
              {githubConnectionStatus && (
                <span className={`badge ms-2 ${githubConnectionStatus === 'success' ? 'bg-success' : 'bg-danger'}`}>
                  {githubConnectionStatus === 'success' ? 'Connected' : 'Connection Failed'}
                </span>
                )}
                </Accordion.Header>
              <Accordion.Body>
                {githubConnectionStatus !== 'success' && <div className="my-3"> Please click <a rel="noreferrer" href="https://github.com/apps/RelvyAI/installations/new" target="_blank">here</a> to install Relvy's GitHub app.</div>}
                {githubConnectionStatus === 'success' && <>
                {selectedRepos.length > 0 && <div className="my-3">
                  <div className="row">
                    <div className="col-md-2">
                      <p><strong>Include?</strong></p>
                    </div>
                    <div className="col-md-6">
                      <p><strong>Repo</strong></p>
                    </div>
                    <div className="col-md-4">
                      <p><strong>Production Branch</strong></p>
                    </div>
                  </div>
                  {selectedRepos.map((repo) => {
                    return <div className="row" key={repo}>
                      <div className="col-md-2">
                        <input type="checkbox" className="form-check-input" id={repo.full_name} value={repo.full_name} checked={repo.selected} onChange={handleGitHubRepoSelection} />
                      </div>
                      <div className="col-md-6">{repo.full_name}</div>
                      <div className="col-md-4">
                        <input type="text" className="form-control" name={repo.full_name} value={repo.branch} onChange={handleGitHubBranchSelection} />
                      </div>
                    </div>
                  })}
                </div>}
                <button className="btn btn-primary custom-btn" style={buttonStyle} onClick={updateGitHubConfig}>
                {isGitHubLoading ? (
                  <>
                    <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                  </>
                  ) : null}
                  Update
                </button>
                </>}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>

      <div className="row my-3">
        <div className="col-md-12">
          <h5 className="my-4">
            Step 4. Connect your JIRA to have Relvy debug your JIRA issues here
            <span className="badge bg-secondary ms-2" style={{ fontSize: '0.5em', fontWeight: 'normal' }}>Optional</span>
          </h5>
          <Accordion alwaysOpen>
            <Accordion.Item key={0} eventKey={"0"}>
              <Accordion.Header>
                Connect JIRA
              {jiraConnectionStatus && (
                <span className={`badge ms-2 ${awsConnectionStatus === 'success' ? 'bg-success' : 'bg-danger'}`}>
                  {jiraConnectionStatus === 'success' ? 'Connected' : 'Connection Failed'}
                </span>
                )}
                </Accordion.Header>
              <Accordion.Body>
                <div className="my-3"> For instructions on generating a token, please see <a rel="noreferrer" href="https://support.atlassian.com/atlassian-account/docs/manage-api-tokens-for-your-atlassian-account/" target="_blank">here</a></div>
                <label htmlFor="jiraDomain">Enter your JIRA domain</label>
                <input type="text" className="form-control my-3" value={jiraDomain} id="jiraDomain" placeholder="your-domain.atlassian.net" onChange={(e) => setJiraDomain(e.target.value)} />
                <label htmlFor="jiraEmail">Enter your JIRA email</label>
                <input type="text" className="form-control my-3" value={jiraEmail} id="jiraEmail" placeholder="" onChange={(e) => setJiraEmail(e.target.value)} />
                <label htmlFor="jiraToken">Enter your JIRA API token</label>
                <input type="password" className="form-control my-3" value={jiraToken} id="jiraToken" placeholder="" onChange={(e) => setJiraToken(e.target.value)} />
                <label htmlFor="jiraProjectKeys">Project keys, comma separated</label>
                <input type="text" className="form-control my-3" value={jiraProjectKeys} id="jiraProjectKeys" placeholder="" onChange={(e) => setJiraProjectKeys(e.target.value)} />
                <label htmlFor="jiraIssueType">Issue Type</label>
                <input type="text" className="form-control my-3" value={jiraIssueType} id="jiraIssueType" placeholder="BUG" onChange={(e) => setJiraIssueType(e.target.value)} />
                <button className="btn btn-primary custom-btn" style={buttonStyle} onClick={connectJira}>
                {isJiraLoading ? (
                  <>
                    <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                  </>
                  ) : null}
                  Connect JIRA
                </button>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>

      <div className="row my-3">
        <div className="col-sm-12 col-md-4 col-md-offset-8">
          <button 
            disabled={services.length === 0 && githubConnectionStatus !== 'success'}
            onClick={saveConfiguration} 
            className="btn btn-primary custom-btn"
            style={{
              backgroundColor: '#5935c9',
              borderColor: '#5935c9',
              color: 'white',
              transition: 'background-color 0.3s, color 0.3s'
            }}
          >
            {getNextButtonText()}
          </button>
        </div>
      </div>
    </div>
  );
};
export default Configuration;
