import { useEffect, useState } from 'react';
import { getLoginInfoFromCookie, makeAuthenticatedRequest } from './utils';
import { useLocation } from 'react-router-dom';

const SlackRedirect = () => {
  const location = useLocation();
  const [status, setStatus] = useState({
    isLoading: true,
    isError: false,
    message: 'Connecting to Slack...'
  });

  const connectSlack = async (loginData) => {
    try {
      const queryParams = new URLSearchParams(location.search);
      const code = queryParams.get('code');

      if (!code) {
        throw new Error('No authorization code provided');
      }

      const response = await makeAuthenticatedRequest(`/api/slack/auth?code=${code}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      });

      const data = await response.json();

      if (!response.ok || data.error) {
        throw new Error(data.error || 'Failed to connect to Slack');
      }
      setStatus({
        isLoading: false,
        isError: false,
        message: 'success'
      });
    } catch (error) {
      setStatus({
        isLoading: false,
        isError: true,
        message: 'Failed to connect to Slack. Please try again later or reach out to us at hello@relvy.ai'
      });
    }
  };

  useEffect(() => {
    getLoginInfoFromCookie(
      connectSlack,
      () => {
        window.location.href = '/';
      }
    );
  }, []);

  if (status.isLoading) {
    return (
      <div className="container text-center my-5">
        <div className="spinner-border text-primary mb-3" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <h4>{status.message}</h4>
      </div>
    );
  }

  if (status.isError) {
    return (
      <div className="container text-center my-5">
        <div className="text-danger mb-4">
          <i className="bi bi-exclamation-circle-fill fs-1"></i>
        </div>
        <h3 className="mb-3">Slack Connection Failed</h3>
        <p className="text-muted">{status.message}</p>
      </div>
    );
  }

  return (
    <div className="container text-center my-5">
      <div className="text-success mb-4">
        <i className="bi bi-check-circle-fill fs-1"></i>
      </div>

      <h2 className="text-success mb-4">
        Successfully connected to Slack! 🎉
      </h2>

      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="card shadow-sm mb-4">
            <div className="card-body">
              <h4 className="card-title mb-3">Next Steps</h4>

              <div className="mb-4">
                <h5 className="mb-2">1. Invite Bot to Channel</h5>
                <p className="text-muted">
                  Add <strong>@Relvy AI</strong> to your channel
                </p>
              </div>

              <div>
                <h5 className="mb-2">2. Auto Monitor Setup</h5>
                <div className="bg-light p-3 rounded">
                  <p className="mb-2">Available Commands:</p>
                  <code className="d-block mb-2">/subscribe-alerts</code>
                  <small className="text-muted">Start receiving Auto Monitor alerts</small>

                  <code className="d-block mt-3 mb-2">/unsubscribe-alerts</code>
                  <small className="text-muted">Stop receiving Auto Monitor alerts</small>
                </div>
              </div>
            </div>
          </div>
          <p className="text-muted">You can close this window now.</p>
        </div>
      </div>
    </div>
  );
};

export default SlackRedirect;
