import React, { useState } from 'react';
import AlertsDashboard from '../components/monitor/AlertsPanel';
import Sidebar from '../components/Sidebar';
import AlertsSummary from '../components/monitor/AlertsSummary';
import { Shield } from 'lucide-react';
import { IoDesktopSharp } from 'react-icons/io5';
import { Button } from 'react-bootstrap';
import withAuth from '../components/withAuth.js';
import { useSearchParams } from 'react-router-dom';

function Monitor(props) {
  const { userData: initialUserData } = props;
  const [searchParams] = useSearchParams();
  const clientSystemIdFromParams = searchParams.get('client_system_id');
  const alertIdFromParams = searchParams.get('alert_id');
  const [selectedAlertId, setSelectedAlertId] = useState(alertIdFromParams || null);

  const clientSystemId = clientSystemIdFromParams || initialUserData.workspaces[0].client_system_id;

  const autoMonitorEnabled = initialUserData.organization.auto_monitor_enabled;
  if (!autoMonitorEnabled){
    return (
      <div className="d-flex">
        <Sidebar clientSystemId={clientSystemIdFromParams} />
        <div className="container-fluid" style={{ marginLeft: '64px' }}>
          <div className="text-center py-5">
            <IoDesktopSharp size={48} className="text-muted mb-3" />
            <h4>Auto Monitor</h4>
            <p className="text-muted">Continuously monitors your logs for anomalies.</p>
            <Button href="https://share.hsforms.com/1CdwukX-VTvCtlcUrj7jg3wrhaij" target="_blank" variant="primary">Request Early Access</Button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="d-flex">
      <Sidebar clientSystemId={clientSystemIdFromParams} />
      <div className="container-fluid" style={{ marginLeft: '64px' }}>
        <div className="row vh-100">
          <div className="col-12 h-100 d-flex flex-column">
            <div className="flex-grow-1">
              <div className="p-4">
                <AlertsSummary 
                  clientSystemId={clientSystemId} 
                  onAlertSelect={setSelectedAlertId}
                />
                <AlertsDashboard 
                  clientSystemId={clientSystemId}
                  selectedAlertId={selectedAlertId}
                  clientSystemIdFromParams={clientSystemIdFromParams}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withAuth(Monitor);
