import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Toast, Nav, InputGroup, Table, Modal } from 'react-bootstrap';
import { Person, Building, Briefcase, Search, PlusLg, Clipboard } from 'react-bootstrap-icons';
import { IoCopy } from 'react-icons/io5';
import '../components/settings.css';
import '../components/demo.css';
import Configuration from './configuration.js';
import withAuth from '../components/withAuth.js';
import { useSearchParams } from 'react-router-dom';
import { makeAuthenticatedRequest } from './utils';

function ProfileForm({ userData, toast, refreshUserData }) {
  const [name, setName] = useState(`${userData.user.first_name} ${userData.user.last_name}`);
  const [email, setEmail] = useState(userData.user.email);
  const [phone, setPhone] = useState('');
  const [loading, setLoading] = useState(false); // Spinner state

  const handleSave = async (e) => {
    e.preventDefault();
    setLoading(true); // Show spinner

    const [first_name, last_name] = name.split(' ');

    try {
      const response = await makeAuthenticatedRequest(`/api/user/${userData.user.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          first_name,
          last_name,
          phone,
        }),
      });

      if (response.ok) {
        toast.setMessage('Profile updated successfully');
        toast.setVariant('success');
        await refreshUserData(); // Refresh user data
      } else {
        toast.setMessage('Failed to update profile');
        toast.setVariant('danger');
      }
    } catch (error) {
      toast.setMessage('Error: ' + error.message);
      toast.setVariant('danger');
    } finally {
      setLoading(false); // Hide spinner
      toast.setShow(true); // Show toast
    }
  };

  return (
    <div>
      <h3 className="mb-4">Your profile</h3>
      <hr className="my-4" width="75%" />
      <Form onSubmit={handleSave}>
        <Form.Group className="mb-4">
          <Form.Label>Name</Form.Label>
          <Form.Text className="text-muted d-block mb-2">
            The name associated with this account
          </Form.Text>
          <Col md={4}>
            <Form.Control
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Col>
        </Form.Group>
        <Form.Group className="mb-4">
          <Form.Label>Email address</Form.Label>
          <Form.Text className="text-muted d-block mb-2">
            The email address associated with this account
          </Form.Text>
          <Col md={4}>
            <Form.Control
              type="email"
              value={email}
              readOnly
              disabled
            />
          </Col>
        </Form.Group>
        <Form.Group className="mb-4">
          <Form.Label>Phone number</Form.Label>
          <Form.Text className="text-muted d-block mb-2">
            The phone number associated with this account
          </Form.Text>
          <Col md={4}>
            <Form.Control
              type="tel"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </Col>
        </Form.Group>
        <Button variant="success" type="submit" disabled={loading}>
          {loading && <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>}
          {loading ? 'Saving...' : 'Save'}
        </Button>
      </Form>
    </div>
  );
}

function WorkspaceGeneralForm({ userData, toast, refreshUserData }) {
  const [workspaceName, setWorkspaceName] = useState(userData.workspaces[0].name);
  const [loading, setLoading] = useState(false); // Spinner state

  const handleSave = async (e) => {
    e.preventDefault();
    setLoading(true); // Show spinner

    try {
      const response = await makeAuthenticatedRequest(`/api/workspace/${userData.workspaces[0].id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: workspaceName,
        }),
      });

      if (response.ok) {
        toast.setMessage('Workspace updated successfully');
        toast.setVariant('success');
        await refreshUserData(); // Refresh user data
      } else {
        toast.setMessage('Failed to update workspace');
        toast.setVariant('danger');
      }
    } catch (error) {
      toast.setMessage('Error: ' + error.message);
      toast.setVariant('danger');
    } finally {
      setLoading(false); // Hide spinner
      toast.setShow(true); // Show toast
    }
  };

  return (
    <div>
      <h3>Workspace</h3>
      <hr className="my-4" width="75%" />
      
      <h4>Details</h4>
      <Form onSubmit={handleSave}>
        <Form.Group className="mb-4">
          <Form.Label>Workspace name</Form.Label>
          <Form.Text className="text-muted d-block mb-2">
            Human-friendly label for your workspace, shown in user interfaces
          </Form.Text>
          <Col md={4}>
            <Form.Control
              type="text"
              value={workspaceName}
              onChange={(e) => setWorkspaceName(e.target.value)}
            />
          </Col>
        </Form.Group>

        <Button variant="success" type="submit" disabled={loading}>
          {loading && <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>}
          {loading ? 'Saving...' : 'Save'}
        </Button>
      </Form>
    </div>
  );
}

function OrganizationGeneralForm({ userData, toast, refreshUserData }) {
  const [orgName, setOrgName] = useState(userData.organization.name);
  const [loading, setLoading] = useState(false); // Spinner state

  const handleSave = async (e) => {
    e.preventDefault();
    setLoading(true); // Show spinner

    try {
      const response = await makeAuthenticatedRequest(`/api/organization/${userData.organization.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: orgName,
        }),
      });

      if (response.ok) {
        toast.setMessage('Organization updated successfully');
        toast.setVariant('success');
        await refreshUserData(); // Refresh user data
      } else {
        toast.setMessage('Failed to update organization');
        toast.setVariant('danger');
      }
    } catch (error) {
      toast.setMessage('Error: ' + error.message);
      toast.setVariant('danger');
    } finally {
      setLoading(false); // Hide spinner
      toast.setShow(true); // Show toast
    }
  };

  return (
    <div>
      <h3>Organization</h3>
      <hr className="my-4" width="75%" />
      
      <h4>Details</h4>
      <Form onSubmit={handleSave}>
        <Form.Group className="mb-4">
          <Form.Label>Organization name</Form.Label>
          <Form.Text className="text-muted d-block mb-2">
            Human-friendly label for your organization, shown in user interfaces
          </Form.Text>
          <Col md={4}>
            <Form.Control
              type="text"
              value={orgName}
              onChange={(e) => setOrgName(e.target.value)}
            />
          </Col>
        </Form.Group>
        {!userData.organization.in_trial && 
        <Col md={4}>
              <div className="mb-3">
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <span className="fs-6">Credits Left:</span>
                  <span className="fs-5 fw-bold">${userData.organization.credits >= 0 ? userData.organization.credits.toFixed(2) : "--"}</span>
                </div>
                <div className="progress" style={{height: "10px"}}>
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{
                      width: `${Math.max(0, (userData.organization.credits / 200) * 100)}%`,
                      backgroundColor: '#5935c9'
                    }}
                    aria-valuenow={userData.organization.credits}
                    aria-valuemin="0"
                    aria-valuemax="200"
                  ></div>
                </div>
              </div>
              </Col>}

        {userData.organization.in_trial && <Form.Group className="mb-4">
          <Form.Label>Credits</Form.Label>
          <Form.Text className="text-muted d-block mb-2">
            Trial Period Ends
          </Form.Text>
          <Col md={4}>
            <Form.Control
              type="text"
              value={`${userData.organization.trial_end_date}`}
              readOnly
              disabled
            />
          </Col>
        </Form.Group>}

        <Button variant="success" type="submit" disabled={loading}>
          {loading && <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>}
          {loading ? 'Saving...' : 'Save'}
        </Button>
      </Form>
    </div>
  );
}

function OrganizationMembersForm({ userData }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [members, setMembers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [inviteLink, setInviteLink] = useState('');
  const [inviteLinkLoading, setInviteLinkLoading] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    if (userData && userData.organization && userData.organization.members) {
      const formattedMembers = userData.organization.members.map(member => ({
        id: member.id,
        name: member.name,
        email: member.email,
        role: member.role
      }));
      setMembers(formattedMembers);
    }
  }, [userData]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleInvite = async () => {
    setInviteLinkLoading(true);
    try {
      const response = await makeAuthenticatedRequest(`/api/organization/${userData.organization.id}/invitation`);
      if (response.ok) {
        const data = await response.json();
        setInviteLink(data.invitation_url);
        setShowModal(true);
      } else {
        console.error('Failed to fetch invite link');
        setToastMessage('Error occurred while generating invite link, please try again later.');
        setShowToast(true);
      }
    } catch (error) {
      console.error('Error fetching invite link:', error);
      setToastMessage('Error occurred while generating invite link, please try again later.');
      setShowToast(true);
    } finally {
      setInviteLinkLoading(false);
    }
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(inviteLink);
    setShowToast(true);
    setToastMessage('Invite link copied to clipboard!');
  };

  const filteredMembers = members.filter(member =>
    member.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    member.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      <h3>Team</h3>
      <hr className="my-4" width="75%" />
      
      <Row className="mb-3 w-75">
        <Col md={4}>
          <InputGroup>
            <InputGroup.Text><Search /></InputGroup.Text>
            <Form.Control
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={handleSearch}
            />
          </InputGroup>
        </Col>
        <Col xs="auto" className="ms-auto">
          <Button variant="success" onClick={handleInvite} disabled={inviteLinkLoading}>
            {inviteLinkLoading ? (
              <>
                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                Loading...
              </>
            ) : (
              <>
                <PlusLg className="me-2" />Invite
              </>
            )}
          </Button>
        </Col>
      </Row>

      <div className="w-75">
        <Table hover>
          <thead>
            <tr>
              <th>Name</th>
              <th>Role</th>
            </tr>
          </thead>
          <tbody>
            {filteredMembers.map(member => (
              <tr key={member.id}>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="rounded-circle bg-primary text-white d-flex justify-content-center align-items-center me-2" style={{width: '32px', height: '32px'}}>
                      {member.name.charAt(0)}
                    </div>
                    <div>
                      <div>{member.name}</div>
                      <small className="text-muted">{member.email}</small>
                    </div>
                  </div>
                </td>
                <td>{member.role}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      <Modal 
        show={showModal} 
        onHide={() => {
          setShowModal(false);
        }}
        centered
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <img src="/RelvyAI_JustLogo.png" alt="Relvy Logo" style={{ width: '30px', marginRight: '10px' }} />
            Invite Your Team!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{ fontFamily: 'Arial, sans-serif', fontSize: '0.9rem', fontWeight: '500', color: '#333', lineHeight: '1.5' }}>
            You can collaborate with your team and invite them using this link.
          </p>
          <InputGroup className="mb-3">
            <Form.Control
              type="text"
              value={inviteLink}
              readOnly
              style={{ fontSize: '0.8rem' }}
            />
            <Button variant="secondary" onClick={handleCopyLink}>
              <IoCopy />
            </Button>
          </InputGroup>
        </Modal.Body>
      </Modal>

      <Toast
        onClose={() => setShowToast(false)}
        show={showToast}
        delay={3000}
        autohide
        style={{
          position: 'fixed',
          bottom: 20,
          right: 20,
        }}
      >
        <Toast.Body>{toastMessage}</Toast.Body>
      </Toast>
    </div>
  );
}

function Settings(props) {
  const { userData: initialUserData } = props;
  const [userData, setUserData] = useState(initialUserData);
  const [activeSection, setActiveSection] = useState('profile');
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastVariant, setToastVariant] = useState('');

  const [cSearchParams] = useSearchParams();
  const clientSystemIdFromParams = cSearchParams.get('client_system_id');

  const toast = {
    show: showToast,
    setShow: setShowToast,
    message: toastMessage,
    setMessage: setToastMessage,
    variant: toastVariant,
    setVariant: setToastVariant,
  };

  const refreshUserData = async () => {
    try {
      const response = await makeAuthenticatedRequest(`/api/user/${initialUserData.user.id}`);
      if (response.ok) {
        const updatedUserData = await response.json();
        setUserData(updatedUserData);
      } else {
        console.error('Failed to fetch user data');
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col md={3} lg={2} className="sidebar bg-light min-vh-100">
          <h5 className="mt-4 mb-3">SETTINGS</h5>
          <Nav className="flex-column">
            <Nav.Link 
              active={activeSection === 'profile'} 
              onClick={() => setActiveSection('profile')}
            >
              <Person className="me-2" />Your profile
            </Nav.Link>
            <Nav.Link 
              active={activeSection.startsWith('organization')} 
              onClick={() => setActiveSection('organization-general')}
            >
              <Building className="me-2" />Organization
            </Nav.Link>
            <Nav className="flex-column ms-3">
              <Nav.Link 
                active={activeSection === 'organization-general'}
                onClick={() => setActiveSection('organization-general')}
              >
                General
              </Nav.Link>
              <Nav.Link 
                active={activeSection === 'organization-members'}
                onClick={() => setActiveSection('organization-members')}
              >
                Members
              </Nav.Link>
            </Nav>
            <Nav.Link 
              active={activeSection.startsWith('workspace')} 
              onClick={() => setActiveSection('workspace-general')}
            >
              <Briefcase className="me-2" />Workspace
            </Nav.Link>
            <Nav className="flex-column ms-3">
              <Nav.Link 
                active={activeSection === 'workspace-general'}
                onClick={() => setActiveSection('workspace-general')}
              >
                General
              </Nav.Link>
              <Nav.Link 
                active={activeSection === 'workspace-datasources'}
                onClick={() => setActiveSection('workspace-datasources')}
              >
                Data Sources
              </Nav.Link>
            </Nav>
          </Nav>
        </Col>
        <Col md={9} lg={10} className="main-content">
          <Container className="py-4">
            {activeSection === 'profile' && <ProfileForm userData={userData} toast={toast} refreshUserData={refreshUserData} />}
            {activeSection === 'organization-general' && <OrganizationGeneralForm userData={userData} toast={toast} refreshUserData={refreshUserData} />}
            {activeSection === 'organization-members' && <OrganizationMembersForm userData={userData} toast={toast} />}
            {activeSection === 'workspace-general' && <WorkspaceGeneralForm userData={userData} toast={toast} refreshUserData={refreshUserData} />}
            {activeSection === 'workspace-datasources' && <Configuration userData={userData} clientSystemIdFromParams={clientSystemIdFromParams} />}
          </Container>
        </Col>
      </Row>
      <Toast
        onClose={() => setShowToast(false)}
        show={showToast}
        delay={3000}
        autohide
        bg={toastVariant}
        className="position-fixed bottom-0 start-50 translate-middle-x m-3"
      >
        <Toast.Body>{toastMessage}</Toast.Body>
      </Toast>
    </Container>
  );
}

export default withAuth(Settings);
